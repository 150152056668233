import { Component } from '@angular/core';

@Component({
  selector: 'app-payment-plan-confirmation',
  standalone: true,
  imports: [],
  templateUrl: './payment-plan-confirmation.component.html',
  styleUrl: './payment-plan-confirmation.component.scss',
})
export class PaymentPlanConfirmationComponent {}
