import { CommonModule } from '@angular/common';
import { Component, inject, OnDestroy, OnInit } from '@angular/core';
import { combineLatest, Subscription } from 'rxjs';

import { calcPaymentBalance } from '../../helpers/helpers';
import { BalanceService } from '../../services/balance/balance.service';
import { ClientSettingsService } from '../../services/client-settings/client-settings.service';
import { PaymentAccessTokenService } from '../../services/payment-access-token/payment-access-token.service';
import { PaymentDestinationService } from '../../services/payment-destination/payment-destination.service';
import { PaymentLibraryService } from '../../services/payment-library/payment-library.service';
import { PendingTransactionsService } from '../../services/pending-transactions/pending-transactions.service';

import { PaymentConfirmationComponent } from './payment-confirmation/payment-confirmation.component';
import { PaymentInformationComponent } from './payment-information/payment-information.component';
import { PaymentSelectionComponent } from './payment-selection/payment-selection.component';
import { PaymentServiceService } from './services/payment-service.service';

@Component({
  selector: 'app-payment',
  standalone: true,
  imports: [
    CommonModule,
    PaymentSelectionComponent,
    PaymentInformationComponent,
    PaymentConfirmationComponent,
  ],
  templateUrl: './payment.component.html',
  styleUrl: './payment.component.scss',
})
export class PaymentComponent implements OnInit, OnDestroy {
  paymentService = inject(PaymentServiceService);
  paymentAccessTokenService = inject(PaymentAccessTokenService);
  paymentLibraryService = inject(PaymentLibraryService);
  paymentDestinationService = inject(PaymentDestinationService);
  clientSettingsService = inject(ClientSettingsService);
  balanceService = inject(BalanceService);
  pendingTransactionsService = inject(PendingTransactionsService);

  paymentLibraryCode: string | undefined = undefined;
  paymentLibrarySubscription: Subscription | undefined;
  balanceDue!: number;

  ngOnInit() {
    this.paymentLibraryService.fetchPaymentLibrary();
    this.paymentService.fetchData();
    this.paymentLibrarySubscription =
      this.paymentLibraryService.response$.subscribe({
        next: (res: string) => {
          if (res !== '') {
            this.paymentLibraryCode = res;
            if (
              this.paymentLibraryCode !== '' ||
              this.paymentLibraryCode !== undefined
            ) {
              if (typeof this.paymentLibraryCode === 'string') {
                this.paymentAccessTokenService.fetchPaymentAccessToken();
                this.paymentLibraryService.generatePaymentLibraryScriptTag(
                  this.paymentLibraryCode
                );
                this.paymentDestinationService.fetchPaymentDestination();
              }
            }
          }
        },
      });

    const balanceRequest = this.balanceService.response$;
    const pendingRequest = this.pendingTransactionsService.response$;

    combineLatest([balanceRequest, pendingRequest]).subscribe({
      next: ([balanceResponse, pendingResponse]) => {
        if (balanceResponse !== null && pendingResponse !== null) {
          this.balanceDue = calcPaymentBalance(
            balanceResponse.balance,
            pendingResponse
          );
        }
      },
    });
  }

  editPayment() {
    this.paymentService.previousPage();
  }

  hasOnlinePaymentMethods() {
    const acceptsCC =
      this.clientSettingsService.getClientSettings().acceptCreditCards;
    const acceptsEC =
      this.clientSettingsService.getClientSettings().acceptElectronicChecks;

    return acceptsCC || acceptsEC;
  }

  ngOnDestroy() {
    this.paymentService.resetPaymentFlow();
    if (this.paymentLibrarySubscription) {
      this.paymentLibrarySubscription.unsubscribe();
    }
  }
}
