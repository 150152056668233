<header class="header">
  <span class="header__logo">
    <img [src]="this.logoGraphic" alt="My Doc Bill, Powered by Zotec" />
    <span class="header__logo__text" *ngIf="this.companyNameDisplay">
      {{ this.companyNameDisplay }}
    </span>
  </span>
  <app-navigation class="header__navigation"></app-navigation>
</header>
<app-hero-image *ngIf="this.isLoginPage()"></app-hero-image>
