{{ this.paymentPlanService.paymentSelections | json }}
{{ this.paymentPlanService.paymentSelectionsCopy | json }}
@if (this.paymentPlanService.paymentLocation === 1) {
  <app-payment-plan-selection />
} @else if (this.paymentPlanService.paymentLocation === 2) {
  <app-payment-plan-information />
  <button (click)="this.submitPayment()">Next</button>
  <button (click)="this.editPayment()">Edit Payment</button>
} @else if (this.paymentPlanService.paymentLocation === 3) {
  <app-payment-plan-confirmation />
  <button (click)="this.paymentPlanService.previousPage()">Back</button>
  <button (click)="this.paymentPlanService.resetPaymentPlanFlow()">
    Pay again
  </button>
}
