@defer (
  when this.clientSettingsService.complete === true && this.balanceService.complete === true && this.pendingTransactionsService.complete === true && this.paperlessBillingService.complete
) {
  <header class="payment-plan__header">
    <h1 class="t-heading payment-plan__header__heading">
      Let's set up a payment plan.
    </h1>
    <h2 class="t-heading-small payment-plan__header__sub-heading">
      Total balance due:
      <span class="payment-plan__header__sub-heading__total">{{
        this.balanceService.getBalance().balance | currency
      }}</span>
    </h2>

    <p class="t-body t-body--spaced">
      You can pay your bill over time with an interest-free payment plan that
      works for your budget. Note that your last payment may be slightly less
      than the stated monthly payment amount so that you only pay the total
      balance you owe.
    </p>
  </header>
  <form
    class="payment-plan__form"
    [formGroup]="paymentPlanForm"
    (ngSubmit)="onPaymentSubmit()">
    <div class="payment-plan__form__radio-group">
      @for (
        paymentOption of this.paymentPlanService.defaultPlans;
        track paymentOption;
        let i = $index
      ) {
        <label class="payment-plan__form__radio-group__label t-body-large">
          <span
            ><span class="t-bold"
              >{{ paymentOption.paymentAmountPerMonth | currency }}/month</span
            >
            for {{ paymentOption.numberOfPayments }} months</span
          >
          <input
            class="payment-plan__form__radio-group__label__input"
            type="radio"
            formControlName="paymentPlan"
            attr.data-testid="{{ 'pay-plan-form-plan-' + i }}"
            name="paymentPlan"
            value="{{ i }}" />
        </label>
      }

      <label class="payment-plan__form__radio-group__label t-body-large">
        <span>None of these plans work for me</span>
        <input
          class="payment-plan__form__radio-group__label__input"
          type="radio"
          formControlName="paymentPlan"
          name="paymentPlan"
          data-testid="pay-plan-form-plan-new-plan"
          value="-1" />
      </label>
    </div>

    @if (this.isPredefinedPlanSelected === false) {
      <div formGroupName="additionalFields" class="ignore-layer">
        <label class="payment-plan__form__label form__label">
          <span class="form__label__text t-body-large"
            >Payment amount (minimum:
            {{ this.minimumPaymentAmount | currency }})</span
          >
          <div class="form__label__input-wrapper--currency">
            <input
              class="form__label__input payment-plan__form__label__input"
              type="text"
              inputmode="numeric"
              formControlName="paymentAmount"
              data-testid="pay-plan-form-payment-amount"
              placeholder="12345"
              required="true"
              aria-required="true" />
          </div>
        </label>
      </div>
    }

    <label class="payment-plan__form__label form__label">
      <span class="form__label__text t-body-large">
        @if (this.isPredefinedPlanSelected) {
          When do you want to make your first payment?
        } @else {
          First Payment
        }
      </span>
      <input
        class="form__label__input payment-plan__form__label__input"
        type="date"
        formControlName="startDate"
        data-testid="pay-plan-form-start-date"
        min="{{ paymentPlanMinStartDateString }}"
        max="{{ paymentPlanMaxStartDateString }}"
        required="true"
        aria-required="true" />
      <!-- [readonly]="isReadOnlyInput"
          (blur)="axErrorHandler()"
          (change)="errorHandler($event)" -->
      <!-- <span
          class="pay__form__errors t-body t-form-error"
          data-testid="pay-form-errors"
          *ngIf="
            this.paymentForm.get('paymentAmount')?.errors &&
            this.paymentInputError !== undefined
          "
          >{{ this.paymentInputError }}</span
        > -->
    </label>

    @if (this.isPredefinedPlanSelected === false) {
      <div formGroupName="additionalFields" class="ignore-layer">
        <label class="payment-plan__form__label form__label">
          <span class="form__label__text t-body-large">Last payment</span>
          <input
            class="form__label__input form__label__input--light payment-plan__form__label__input"
            type="date"
            formControlName="endDate"
            data-testid="pay-plan-form-end-date"
            readonly="readonly"
            min="{{ paymentPlanMinEndDateString }}"
            max="{{ paymentPlanMaxEndDateString }}"
            required="true"
            aria-required="true" />
        </label>
      </div>
    }

    <label class="payment-plan__form__label form__label">
      <span class="form__label__text t-body-large">
        Email address for automatic payment reminders
      </span>
      <input
        class="form__label__input payment-plan__form__label__input"
        type="email"
        formControlName="emailAddress"
        data-testid="pay-plan-form-email-address"
        placeholder="me@example.com"
        required="true"
        aria-required="true" />
    </label>

    <div class="payment-plan__form__buttons">
      <button
        class="payment-plan__form__buttons__button payment-plan__form__buttons__button--submit button"
        data-testid="pay-plan-form-submit-button"
        [disabled]="!this.paymentPlanForm.valid"
        type="submit">
        Next
      </button>
      <a
        routerLink="../home"
        class="payment-plan__form__buttons__button payment-plan__form__buttons__button--cancel button-secondary button-secondary--outline"
        data-testid="pay-plan-form-submit">
        Cancel
      </a>
    </div>
  </form>
} @placeholder {
  Loading...
}
