import { CommonModule } from '@angular/common';
import {
  AfterViewInit,
  Component,
  ElementRef,
  EnvironmentInjector,
  Inject,
  Input,
  OnInit,
  ViewChild,
  inject,
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { LoginHelpers } from '../helpers/login';
import { ILogger } from '../observability/logger';
import { LOGGER_FACTORY, LoggerFactory } from '../observability/provider';
import { AuthService } from '../services/auth/auth.service';
import {
  BillingGroupSelectionService,
  billingGroupSelectionResponse,
} from '../services/billing-group-selection/billing-group-selection.service';
import { BrowserService } from '../services/browser/browser.service';
import { ClientSettingsService } from '../services/client-settings/client-settings.service';
import { VirtualSiteService } from '../services/virtual-site/virtual-site.service';
import { SvgComponent } from '../svg/svg.component';

@Component({
  selector: 'app-billing-group-modal',
  standalone: true,
  templateUrl: './billing-group-modal.component.html',
  styleUrl: './billing-group-modal.component.scss',
  imports: [SvgComponent, CommonModule],
  providers: [BillingGroupModalComponent],
})
export class BillingGroupModalComponent implements AfterViewInit, OnInit {
  private logger: ILogger;

  @ViewChild('billingGroupModal')
  billingGroupModal!: ElementRef<HTMLDialogElement>;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  billingGroups: any = [];

  @Input({ required: true }) needsBillingGroup!: boolean;

  injector = inject(EnvironmentInjector);
  activatedRoute = inject(ActivatedRoute);
  router = inject(Router);
  authService = inject(AuthService);
  billingGroupSelectionService = inject(BillingGroupSelectionService);
  clientSettingsService = inject(ClientSettingsService);
  virtualSiteService = inject(VirtualSiteService);
  browserService = inject(BrowserService);

  constructor(@Inject(LOGGER_FACTORY) loggerFactory: LoggerFactory) {
    this.logger = loggerFactory('BillingGroupModalComponent');
  }

  ngOnInit(): void {
    this.billingGroupSelectionService.fetchBillingGroups();
    this.billingGroupSelectionService.response$.subscribe({
      next: (res: billingGroupSelectionResponse) => {
        if (res) {
          this.billingGroups = res;
        }
      },
    });
  }

  ngAfterViewInit(): void {
    if (this.needsBillingGroup) {
      this.billingGroupModal.nativeElement.showModal();
    }
  }

  async onClick(billingGroupId: string) {
    const result = await this.authService.patchLogin(billingGroupId);

    if (result.isSuccess) {
      this.logger.info('Token Patched');
      await LoginHelpers.loggedInWithTokenRedirect(this.injector, this.logger);
    }
  }
}
