import { HttpClient } from '@angular/common/http';
import { Inject, Injectable, inject } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { firstValueFrom } from 'rxjs';

import { environment } from '../../../environments/environment';
import { isNullEmptyOrWhitespace } from '../../helpers/string';
import { ILogger } from '../../observability/logger';
import { LOGGER_FACTORY, LoggerFactory } from '../../observability/provider';
import { BrowserService } from '../browser/browser.service';
import { ClientSettingsService } from '../client-settings/client-settings.service';
import { VirtualSiteService } from '../virtual-site/virtual-site.service';

export interface authPostResponse {
  isSuccess?: boolean;
  error?: string;
  token?: string;
  needsBillingGroup?: boolean;
}

export interface authPatchResponse {
  isSuccess: boolean;
  error: string;
  newToken: string;
}

export interface LoginResult {
  isSuccess: boolean;
  error?: string;
}

export interface JwtPayload {
  NeedsBillingGroup: boolean;
}

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  endPointBase: string = '/Authentication';

  private logger: ILogger;

  activatedRoute = inject(ActivatedRoute);
  router = inject(Router);

  browserService = inject(BrowserService);
  clientSettingsService = inject(ClientSettingsService);
  virtualSiteService = inject(VirtualSiteService);

  constructor(
    private http: HttpClient,
    @Inject(LOGGER_FACTORY) loggerFactory: LoggerFactory
  ) {
    this.logger = loggerFactory('AuthService');
  }

  isAuthenticated(): boolean {
    if (this.isPartiallyAuthenticated()) {
      return false;
    }

    const token = localStorage.getItem('token');
    if (token) {
      return true;
    } else return false;
  }

  isPartiallyAuthenticated(): boolean {
    const token = localStorage.getItem('token');
    if (!token) {
      return false;
    }

    try {
      const payload = JSON.parse(atob(token.split('.')[1])) as JwtPayload;
      return payload.NeedsBillingGroup;
    } catch (error: unknown) {
      this.logger.warn('Failed to parse JWT!');
    }

    return false;
  }

  public get token(): string | null {
    return localStorage.getItem('token');
  }

  async login(
    patientId: number,
    clientId: string,
    billingGroup: string,
    dobMonth: number,
    dobDay: number,
    dobYear: number,
    siteUrl: string
  ): Promise<LoginResult> {
    try {
      const httpResposne = await firstValueFrom(
        this.http.post<authPostResponse>(
          environment.apiUrl + this.endPointBase,
          {
            patientId: String(patientId),
            clientId: clientId,
            billingGroup: billingGroup,
            dobMonth: String(dobMonth),
            dobDay: String(dobDay),
            dobYear: String(dobYear),
            siteUrl: siteUrl,
          },
          {
            observe: 'response',
          }
        )
      );

      const response = httpResposne.body as authPostResponse;

      const success = response.isSuccess ?? false;

      if (!success) {
        this.logger.warn('Login Failed', { error: response.error });
        return {
          isSuccess: false,
          error: response.error,
        } as LoginResult;
      }

      if (response.token && !isNullEmptyOrWhitespace(response.token!)) {
        localStorage.setItem('token', response.token!);
        return { isSuccess: true } as LoginResult;
      }
    } catch (error: unknown) {
      this.logger.error('Login Request Failed', undefined, error);
      return {
        isSuccess: false,
      } as LoginResult;
    }

    throw new Error('Unexpected Login Result');
  }

  async patchLogin(billingGroupId: string): Promise<LoginResult> {
    try {
      const httpResposne = await firstValueFrom(
        this.http.patch<authPatchResponse>(
          environment.apiUrl + this.endPointBase,
          {
            billingGroupId: String(billingGroupId),
          },
          {
            observe: 'response',
          }
        )
      );

      const response = httpResposne.body as authPatchResponse;

      const success = response.isSuccess ?? false;

      if (!success) {
        this.logger.warn('Patch Login Failed', { error: response.error });
        return {
          isSuccess: false,
          error: response.error,
        } as LoginResult;
      }

      if (response.newToken && !isNullEmptyOrWhitespace(response.newToken!)) {
        localStorage.setItem('token', response.newToken!);
        return { isSuccess: true } as LoginResult;
      }
    } catch (error: unknown) {
      this.logger.error('Patch Login Request Failed', undefined, error);
      return {
        isSuccess: false,
      } as LoginResult;
    }

    throw new Error('Unexpected Patch Login Result');
  }

  async logout(): Promise<void> {
    try {
      const success = await firstValueFrom(
        this.http.delete<boolean>(environment.apiUrl + this.endPointBase, {
          observe: 'response' as const,
        })
      );

      if (!success) {
        this.logger.warn('Revoking Token Failed!');
      }
    } catch (error: unknown) {
      this.logger.error('Logout Failed!', undefined, error);
    } finally {
      await this.purgeTokenAndRedirect();
    }
  }

  private async purgeTokenAndRedirect() {
    localStorage.removeItem('token');
    const success = await this.router.navigate(['/login']);

    if (!success) {
      this.logger.warn('Navigate to /login after logout failed!', undefined);
    }
  }
}
