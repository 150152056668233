import { HttpClient, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

import { environment } from '../../../environments/environment';

export interface accountLookupRequest {
  communicationMethod: string;
  destination: string;
  dobMonth: string;
  dobDay: string;
  dobYear: string;
  siteUrl: string;
}

export interface accountLookupResponse {
  isSuccess: boolean;
  error: string;
  minutesToExpire: number;
}

@Injectable({
  providedIn: 'root',
})
export class AccountLookupService {
  endPointBase: string = '/AccountLookUp';

  response!: accountLookupResponse | null;
  loading: boolean = false;
  complete: boolean = false;

  private responseSubject = new BehaviorSubject<accountLookupResponse | null>(
    this.response
  );
  response$ = this.responseSubject.asObservable();

  constructor(private http: HttpClient) {}

  fetchAccountLookup(formData: accountLookupRequest) {
    this.loading = true;
    this.http
      .post<accountLookupResponse>(
        environment.apiUrl + this.endPointBase,
        formData,
        {
          observe: 'response',
        }
      )
      .subscribe({
        next: (res: HttpResponse<accountLookupResponse>) => {
          if (res.body && res.status === 200) {
            this.loading = false;
            this.response = res.body;
            this.responseSubject.next(res.body);
            this.complete = true;
          }
        },
        complete: () => {
          this.loading = false;
          this.complete = true;
        },
      });
  }

  getAccountLookup() {
    return this.response; // Return data stored in the service
  }

  resetAccountLookup() {
    this.responseSubject.next(null);
  }
}
