import { CommonModule } from '@angular/common';
import { HttpResponse } from '@angular/common/http';
import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  Inject,
  Input,
  OnInit,
  Output,
  ViewChild,
  inject,
} from '@angular/core';

import { ILogger } from '../../observability/logger';
import { LOGGER_FACTORY, LoggerFactory } from '../../observability/provider';
import { UspsService, uspsResponse } from '../../services/usps/usps.service';
import { SvgComponent } from '../../svg/svg.component';

export interface AddressInfo {
  isSuccess: boolean;
  address1: string | undefined | null;
  address2: string | undefined | null;
  zip: string | undefined | null;
  city: string | undefined | null;
  state: string | undefined | null;
  addressIndicator: string | undefined | null;
}

@Component({
  selector: 'app-usps-modal',
  standalone: true,
  imports: [CommonModule, SvgComponent],
  templateUrl: './usps-modal.component.html',
  styleUrl: './usps-modal.component.scss',
})
export class UspsModalComponent implements AfterViewInit, OnInit {
  private readonly logger: ILogger;

  uspsService = inject(UspsService);
  uspsComplete = false;

  isEnteredAddress: boolean = false;
  isUspsAddress: boolean = false;

  @ViewChild('uspsDialog') uspsDialog!: ElementRef<HTMLDialogElement>;

  @Input({ required: true }) inputAddress1!: string;
  @Input({ required: true }) inputAddress2!: string;
  @Input({ required: true }) inputZip!: string;
  @Input({ required: true }) inputCity!: string;
  @Input({ required: true }) inputState!: string;
  @Input({ required: true }) addressVerification!: boolean;

  @Output() uspsAddressEmitter = new EventEmitter<AddressInfo>();
  @Output() uspsAddressCancelEmitter = new EventEmitter<string>();

  uspsAddress: AddressInfo = {
    isSuccess: false,
    address1: '',
    address2: '',
    zip: '',
    city: '',
    state: '',
    addressIndicator: '',
  };

  inputAddress: AddressInfo = {
    isSuccess: false,
    address1: '',
    address2: '',
    zip: '',
    city: '',
    state: '',
    addressIndicator: '',
  };

  constructor(@Inject(LOGGER_FACTORY) loggerFactory: LoggerFactory) {
    this.logger = loggerFactory('PaperlessBillingModalComponent');
  }

  ngOnInit(): void {
    this.verifyAddress();
  }
  ngAfterViewInit(): void {
    if (this.addressVerification) {
      this.uspsDialog.nativeElement.showModal();
    }
  }

  showUspsModal() {
    this.uspsDialog.nativeElement.showModal();
  }

  closeUspsModal() {
    this.uspsDialog.nativeElement.close();
  }

  cancelUspsModal() {
    this.addressVerification = false;
    this.uspsAddressCancelEmitter.emit('cancelled');
    this.uspsDialog.nativeElement.close();
  }

  radioSelectEnteredAddress() {
    this.isEnteredAddress = true;
    this.isUspsAddress = false;
  }

  radioSelectUspsAddress() {
    this.isEnteredAddress = false;
    this.isUspsAddress = true;
  }

  /* istanbul ignore next Services should be tested within their own specific test files */
  verifyAddress() {
    this.uspsService
      .validateAddress({
        address1: this.inputAddress1,
        address2: this.inputAddress2,
        zip: this.inputZip,
        city: this.inputCity,
        state: this.inputState,
      })
      .subscribe({
        next: (res: HttpResponse<uspsResponse>) => {
          if (res.status == 200 && res.body?.isSuccess == true) {
            this.uspsAddress.isSuccess = res.body.isSuccess;
            this.uspsAddress.address1 =
              res.body.addressInfo.address.streetAddress;
            this.uspsAddress.address2 =
              res.body.addressInfo.address.secondaryAddress;
            this.uspsAddress.zip = res.body.addressInfo.address.zipCode;
            this.uspsAddress.city = res.body.addressInfo.address.city;
            this.uspsAddress.state = res.body.addressInfo.address.state;
            this.uspsComplete = true;

            this.isEnteredAddress = true;
          } else if (res.status == 200 && res.body?.isSuccess == false) {
            this.uspsAddress.address1 = 'USPS could not find a valid address';
            this.uspsAddress.address2 = '';
            this.uspsAddress.zip = '';
            this.uspsAddress.city = '';
            this.uspsAddress.state = '';
          }
        },
        error: (error: unknown) => {
          this.logger.error('Failed to validate address', undefined, error);
        },
      });
  }

  useVerifiedAddress() {
    if (this.isEnteredAddress) {
      this.inputAddress.address1 = this.inputAddress1;
      this.inputAddress.address2 = this.inputAddress2;
      this.inputAddress.zip = this.inputZip;
      this.inputAddress.city = this.inputCity;
      this.inputAddress.state = this.inputState;

      this.uspsAddressCancelEmitter.emit('submit');
      this.uspsAddressEmitter.emit(this.inputAddress);
      this.closeUspsModal();
    } else if (this.isUspsAddress) {
      this.uspsAddressCancelEmitter.emit('submit');
      this.uspsAddressEmitter.emit(this.uspsAddress);
      this.closeUspsModal();
    } else {
      this.closeUspsModal();
    }
  }
}
