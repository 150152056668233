import { CommonModule } from '@angular/common';
import {
  AfterViewInit,
  Component,
  ElementRef,
  ViewChild,
  inject,
} from '@angular/core';
import { RouterModule } from '@angular/router';

import { BannerComponent } from '../communication-banner/banner.component';
import { AuthService } from '../services/auth/auth.service';
import { ClientSettingsService } from '../services/client-settings/client-settings.service';
import { PaperlessBillingService } from '../services/paperless-billing/paperless-billing.service';
import { SvgComponent } from '../svg/svg.component';

@Component({
  selector: 'app-navigation',
  standalone: true,
  templateUrl: './navigation.component.html',
  styleUrl: './navigation.component.scss',
  imports: [CommonModule, RouterModule, SvgComponent],
  providers: [BannerComponent],
})
export class NavigationComponent implements AfterViewInit {
  @ViewChild('NavItems') navItems!: ElementRef<HTMLUListElement>;
  @ViewChild('NavButton') navButton!: ElementRef<HTMLButtonElement>;

  bannerComponent = inject(BannerComponent);
  paperlessBillingService = inject(PaperlessBillingService);
  clientSettingsService = inject(ClientSettingsService);

  constructor(public authService: AuthService) {}

  async onLogoutClick() {
    await this.authService.logout();
  }

  ngAfterViewInit() {
    if (this.navItems.nativeElement.classList.contains('is-active')) {
      this.navButton.nativeElement.setAttribute('aria-expanded', 'true');
    }
  }

  menuButtonClickHandler() {
    this.showHideNav();
  }

  showHideNav() {
    if (this.navItems.nativeElement.classList.contains('is-active')) {
      this.navButton.nativeElement.setAttribute('aria-expanded', 'false');
      this.navItems.nativeElement.classList.remove('is-active');
    } else {
      this.navItems.nativeElement.classList.add('is-active');
      this.navButton.nativeElement.setAttribute('aria-expanded', 'true');
    }
  }
}
