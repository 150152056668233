import { CommonModule, formatDate } from '@angular/common';
import { HttpResponse } from '@angular/common/http';
import { Component, Inject, LOCALE_ID, inject, OnInit } from '@angular/core';
import {
  AbstractControl,
  FormBuilder,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
  ValidationErrors,
  ValidatorFn,
  Validators,
} from '@angular/forms';
import { Router } from '@angular/router';
import { BehaviorSubject, Subscription } from 'rxjs';

import { formatPhone } from '../../helpers/helpers';
import { ILogger } from '../../observability/logger';
import { LOGGER_FACTORY, LoggerFactory } from '../../observability/provider';
import { ClientSettingsService } from '../../services/client-settings/client-settings.service';
import {
  DemographicsService,
  demographicsResponse,
} from '../../services/demographics/demographics.service';
import {
  PaperlessBillingService,
  cityStateResponse,
} from '../../services/paperless-billing/paperless-billing.service';
import {
  AddressInfo,
  UspsModalComponent,
} from '../../usps-modal/usps-modal/usps-modal.component';

@Component({
  selector: 'app-demographics',
  standalone: true,
  imports: [CommonModule, ReactiveFormsModule, FormsModule, UspsModalComponent],
  templateUrl: './demographics.component.html',
  styleUrl: './demographics.component.scss',
})
export class DemographicsComponent implements OnInit {
  private readonly logger: ILogger;
  private clientSettingsSubscription!: Subscription;

  private formScreen = new BehaviorSubject<string>('');
  formScreen$ = this.formScreen.asObservable();
  private formScreenSubscription!: Subscription;

  needAddressVerify = false;
  addressChanged = false;

  isBadMobile = false;
  isBadEmail = false;

  type = '';
  termsOfUseUrl!: string | null;
  selectedGender = '';

  guarantorForm!: FormGroup | null;
  patientsForm!: FormGroup | null;

  formErrors = {
    badMobileFormat: 'You must input the phone number as xxx-xxx-xxxx',
    badMobile:
      'The number you have entered cannot accept text messages. Please use another number.',
    badEmail:
      'The email you have entered cannot receive emails properly. Please use another email.',
  };

  providedGenders = ['Male', 'Female', 'Other'];

  patients: demographicsResponse[] = [];
  selectedPatientId = '';
  isPatientSelected = false;

  guarantor: demographicsResponse = {
    isGuarantor: false,
    personId: 0,
    firstName: '',
    middleInitial: '',
    lastName: '',
    dateOfBirth: new Date(),
    gender: '',
    address1: '',
    address2: '',
    city: '',
    state: '',
    zip: '',
    emailAddress: '',
    emailAddressIndicator: '',
    mobilePhone: '',
    mobilePhoneIndicator: '',
    homePhone: '',
    workPhone: '',
    disallowPatientPortalInformation: false,
  };

  demographicsService = inject(DemographicsService);
  clientSettingsService = inject(ClientSettingsService);
  paperlessBillingService = inject(PaperlessBillingService);
  private router = inject(Router);

  constructor(
    private fb: FormBuilder,
    @Inject(LOGGER_FACTORY) loggerFactory: LoggerFactory,
    @Inject(LOCALE_ID) public locale: string
  ) {
    this.logger = loggerFactory('DemographicsComponent');
  }

  radioSelectPatients() {
    this.formScreen.next('patients');
    this.type = 'patients';
    this.guarantorForm?.reset();
    // this.guarantorForm?.controls['verifyAddress'].disable();
    this.isPatientSelected = false;
    this.needAddressVerify = false;
    // this.addressChanged = false;
  }

  radioSelectGuarantor() {
    this.formScreen.next('guarantor');
    this.type = 'guarantor';
    this.isPatientSelected = false;
    if (this.patientsForm) {
      this.selectedPatientId = '';
    }
  }

  showUspsModal() {
    if (this.guarantorForm?.value.verifyAddress === false) {
      this.needAddressVerify = true;
    } else {
      this.needAddressVerify = false;
    }
  }

  // checkAddress() {
  //   this.addressChanged = true;
  //   this.guarantorForm?.controls['verifyAddress'].enable();
  // }

  uspsAddressEmitter(verifiedAddress: AddressInfo) {
    if (this.guarantorForm) {
      this.guarantorForm.patchValue({
        address1: verifiedAddress.address1,
        address2: verifiedAddress.address2,
        zip: verifiedAddress.zip,
        city: verifiedAddress.city,
        state: verifiedAddress.state,
      });
    }
  }

  uspsAddressCancelEmitter(isCanceled: string) {
    if (isCanceled === 'cancelled') {
      // this.addressChanged = false;
      this.needAddressVerify = false;
      this.guarantorForm?.patchValue({ verifyAddress: false });
    }
  }

  ngOnInit() {
    this.demographicsService.fetchDemographics();
    this.demographicsService.response$.subscribe({
      next: (demographics: demographicsResponse[]) => {
        this.formScreen.next('guarantor');
        this.type = 'guarantor';

        if (demographics != null) {
          demographics.forEach(person => {
            if (person.isGuarantor) {
              this.guarantor = person;

              this.formScreenSubscription = this.formScreen$.subscribe(
                (res: string) => {
                  this.type = res;

                  if (res === 'guarantor') {
                    this.guarantorForm = this.fb.group({
                      name: [
                        person.firstName + ' ' + this.guarantor.lastName,
                        [Validators.required],
                      ],
                      address1: [person.address1, [Validators.required]],
                      address2: [person.address2, []],
                      zip: [
                        person.zip,
                        [Validators.required, Validators.pattern('[0-9]{5}$')],
                      ],
                      city: [person.city, [Validators.required]],
                      state: [person.state, [Validators.required]],
                      verifyAddress: [
                        person.address1 != null &&
                          person.zip != null &&
                          person.city != null &&
                          person.state != null,
                        [Validators.required],
                      ],
                      email: [
                        person.emailAddress,
                        [
                          Validators.required,
                          Validators.email,
                          this.validateBadEmail(),
                        ],
                      ],
                      mobilePhone: [
                        formatPhone(person.mobilePhone),
                        [
                          Validators.required,
                          Validators.pattern('^([0-9]{3}-[0-9]{3}-[0-9]{4})$'),
                          this.validateBadMobile(),
                        ],
                      ],
                      homePhone: [
                        formatPhone(person.homePhone),
                        [Validators.pattern('^([0-9]{3}-[0-9]{3}-[0-9]{4})$')],
                      ],
                      workPhone: [
                        formatPhone(person.workPhone),
                        [Validators.pattern('^([0-9]{3}-[0-9]{3}-[0-9]{4})$')],
                      ],
                    });

                    if (this.guarantor.mobilePhoneIndicator === 'B') {
                      this.isBadMobile = true;
                    }

                    if (this.guarantor.emailAddressIndicator === 'B') {
                      this.isBadEmail = true;
                    }
                  }
                }
              );
            } else {
              this.patients.push(person);
            }
          });
        }
      },
    });

    this.clientSettingsSubscription =
      this.clientSettingsService.response$.subscribe({
        next: () => {
          this.termsOfUseUrl =
            this.clientSettingsService.getClientSettings().termsOfUseUrl;
        },
      });

    this.formScreenSubscription = this.formScreen$.subscribe((res: string) => {
      this.type = res;
      this.patientsForm = this.fb.group({
        firstName: ['', [Validators.required]],
        middleInitial: ['', []],
        lastName: ['', [Validators.required]],
        dateOfBirth: ['', [Validators.required]],
        gender: ['', [Validators.required]],
      });
    });
  }

  /* istanbul ignore next Services should be tested within their own specific test files */
  patchDemographics() {
    if (this.guarantorForm) {
      this.guarantor.emailAddress = this.guarantorForm.value.email;
      this.guarantor.address1 = this.guarantorForm.value.address1;
      this.guarantor.address2 = this.guarantorForm.value.address2;
      this.guarantor.zip = this.guarantorForm.value.zip;
      this.guarantor.city = this.guarantorForm.value.city;
      this.guarantor.state = this.guarantorForm.value.state;
      this.guarantor.homePhone = this.guarantorForm.value.homePhone;
      this.guarantor.workPhone = this.guarantorForm.value.workPhone;
      this.guarantor.mobilePhone = this.guarantorForm.value.mobilePhone;

      this.guarantor.homePhone = this.guarantor.homePhone.replaceAll('-', '');
      this.guarantor.workPhone = this.guarantor.workPhone.replaceAll('-', '');
      this.guarantor.mobilePhone = this.guarantor.mobilePhone.replaceAll(
        '-',
        ''
      );

      this.demographicsService.patchDemographicInfo(this.guarantor).subscribe({
        next: (res: HttpResponse<demographicsResponse>) => {
          if (res.status == 200 && this.guarantorForm) {
            this.router.navigate(['/app/home']);
          }
        },
      });
    }
  }

  /* istanbul ignore next Services should be tested within their own specific test files */
  disableDemographics() {
    if (this.guarantorForm) {
      this.guarantor.disallowPatientPortalInformation = true;

      this.demographicsService.patchDemographicInfo(this.guarantor).subscribe({
        next: (res: HttpResponse<demographicsResponse>) => {
          if (res.status == 200 && this.guarantorForm) {
            this.router.navigate(['/app/home']);
          }
        },
      });
    }
  }

  /* istanbul ignore next Services should be tested within their own specific test files */
  patchPatients() {
    if (this.patientsForm) {
      const id = Number(this.selectedPatientId);
      const foundPatient = this.patients.find(p => p.personId === id);

      if (foundPatient != undefined) {
        (foundPatient.firstName = this.patientsForm.value.firstName),
          (foundPatient.middleInitial = this.patientsForm.value.middleInitial),
          (foundPatient.lastName = this.patientsForm.value.lastName),
          (foundPatient.dateOfBirth = new Date(
            this.patientsForm.value.dateOfBirth
          )),
          (foundPatient.gender = this.selectedGender);

        this.demographicsService.patchDemographicInfo(foundPatient).subscribe({
          next: (res: HttpResponse<demographicsResponse>) => {
            if (res.status == 200 && this.guarantorForm) {
              this.router.navigate(['/app/home']);
            }
          },
        });
      }
    }
  }

  /* istanbul ignore next Services should be tested within their own specific test files */
  disablePatient() {
    if (this.patientsForm) {
      const id = Number(this.selectedPatientId);
      const foundPatient = this.patients.find(p => p.personId === id);

      if (foundPatient != undefined) {
        foundPatient.disallowPatientPortalInformation = true;

        this.demographicsService.patchDemographicInfo(foundPatient).subscribe({
          next: (res: HttpResponse<demographicsResponse>) => {
            if (res.status == 200 && this.guarantorForm) {
              this.router.navigate(['/app/home']);
            }
          },
        });
      }
    }
  }

  async cancelForm() {
    await this.router.navigate(['/app/home']);
  }

  /* istanbul ignore next Services should be tested within their own specific test files */
  updateCityState() {
    if (
      this.guarantorForm &&
      this.guarantorForm.get('zip')?.value.length === 5 &&
      !this.guarantorForm.get('zip')?.errors
    ) {
      this.paperlessBillingService
        .fetchCityStateInfo({ zipCode: this.guarantorForm?.value.zip })
        .subscribe({
          next: (res: HttpResponse<cityStateResponse>) => {
            if (
              res.status == 200 &&
              res.body?.success == true &&
              this.guarantorForm
            ) {
              this.guarantorForm?.get('city')?.setValue(res.body.city);
              this.guarantorForm?.get('state')?.setValue(res.body.state);
            }
          },
          error: (error: unknown) => {
            this.logger.error(
              'Failed to fetch city and state',
              undefined,
              error
            );
          },
        });
    }
  }

  selectGender(option: string): void {
    const splitOption = option.split(' ');
    const value = splitOption[splitOption.length - 1];

    if (value === 'Male') {
      this.selectedGender = 'M';
    } else if (value === 'Female') {
      this.selectedGender = 'F';
    } else {
      this.selectedGender = 'U';
    }
  }

  displaySelectedPatient(option: string): void {
    this.isPatientSelected = true;

    const id = Number(option);

    const foundPatient = this.patients.find(p => p.personId === id);

    if (this.patientsForm) {
      this.patientsForm.patchValue({
        firstName: foundPatient?.firstName,
        middleInitial: foundPatient?.middleInitial,
        lastName: foundPatient?.lastName,
        dateOfBirth:
          foundPatient != undefined
            ? formatDate(foundPatient.dateOfBirth, 'dd-MM-yyyy', this.locale)
            : '',
      });

      if (foundPatient?.gender === 'M') {
        this.patientsForm.patchValue({ gender: 'Male' });
      } else if (foundPatient?.gender === 'F') {
        this.patientsForm.patchValue({ gender: 'Female' });
      } else {
        this.patientsForm.patchValue({ gender: 'Other' });
      }
    }
  }

  /* istanbul ignore next Custom Validator Works and shows Error in test*/
  validateBadMobile(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const enteredPhoneNumber = control.value;

      if (
        this.isBadMobile &&
        enteredPhoneNumber ===
          formatPhone(
            this.paperlessBillingService.getPaperlessBillingInfo().currentMobile
          )
      ) {
        return { badMobile: true };
      } else {
        return null;
      }
    };
  }

  /* istanbul ignore next Custom Validator Works and shows Error in test*/
  validateBadEmail(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const enteredEmail = control.value;

      if (
        this.isBadEmail &&
        enteredEmail ===
          this.paperlessBillingService.getPaperlessBillingInfo().currentEmail
      ) {
        return { badEmail: true };
      } else {
        return null;
      }
    };
  }

  ngOnDestory() {
    this.formScreenSubscription.unsubscribe();
    this.clientSettingsSubscription.unsubscribe();
    this.guarantorForm?.controls['verifyAddress'].disable();
    this.isPatientSelected = false;
    this.needAddressVerify = false;
    //this.addressChanged = false;
  }
}
