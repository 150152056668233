import { HttpClient, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

import { environment } from '../../../environments/environment';

export interface feedbackResponse {
  isSuccess: boolean;
  surveyUrl: string;
}

@Injectable({
  providedIn: 'root',
})
export class FeedbackService {
  endPointBase: string = '/Feedback';
  PaymentEndpointBase: string = '/PaymentFeedback';

  response: feedbackResponse = {
    isSuccess: false,
    surveyUrl: '',
  };

  paymentFeedbackResponse: feedbackResponse = {
    isSuccess: false,
    surveyUrl: '',
  };

  complete: boolean = false;

  responseSubject = new BehaviorSubject<feedbackResponse | null>(null);
  response$ = this.responseSubject.asObservable();

  paymentFeedbackResponseSubject = new BehaviorSubject<feedbackResponse | null>(
    null
  );
  paymentFeedbackResponse$ = this.paymentFeedbackResponseSubject.asObservable();

  constructor(private http: HttpClient) {}

  fetchFeedbackUrl(formData: unknown) {
    this.http
      .post<feedbackResponse>(
        environment.apiUrl + this.endPointBase,
        formData,
        { observe: 'response' }
      )
      .subscribe({
        next: (res: HttpResponse<feedbackResponse>) => {
          if (res.status === 200 && res.body) {
            this.response = res.body;
            this.responseSubject.next(res.body);
            this.complete = true;
          }
        },
      });
  }

  fetchPaymentFeedbackUrl() {
    this.http
      .post<feedbackResponse>(
        environment.apiUrl + this.PaymentEndpointBase,
        {},
        { observe: 'response' }
      )
      .subscribe({
        next: (res: HttpResponse<feedbackResponse>) => {
          if (res.status === 200 && res.body) {
            this.paymentFeedbackResponse = res.body;
            this.paymentFeedbackResponseSubject.next(res.body);
            this.complete = true;
          }
        },
      });
  }

  getFeedbackSurveyInfo() {
    return this.response; // Return data stored in the service
  }

  getPaymentFeedbackSurveyInfo() {
    return this.paymentFeedbackResponse;
  }
}
