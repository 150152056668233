import { Component, inject } from '@angular/core';
import { RouterModule } from '@angular/router';

import { ClientSettingsService } from '../services/client-settings/client-settings.service';

@Component({
  selector: 'app-footer',
  standalone: true,
  imports: [RouterModule],
  templateUrl: './footer.component.html',
  styleUrl: './footer.component.scss',
})
export class FooterComponent {
  clientSettingsService = inject(ClientSettingsService);

  termsOfUseUrl: string =
    this.clientSettingsService.getClientSettings().termsOfUseUrl;
  privacyPolicyUrl: string =
    this.clientSettingsService.getClientSettings().privacyPolicyUrl;
}
