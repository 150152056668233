@defer (
  when this.balanceService.complete === true && this.clientSettingsService.complete === true && this.paperlessBillingService.complete === true && this.pendingTransactionsService.complete === true
) {
  <app-banner type="paperless"></app-banner>
  <div class="home__header">
    <h1 class="home__header__heading t-heading-small">Current Bill</h1>
    <button
      *ngIf="this.balanceService.getBalance().balance !== 0"
      data-testid="home-header-button-statement"
      class="home__header__button button-secondary"
      disabled="{{ this.statementService.loading === true ? 'disabled' : '' }}"
      (click)="
        generateStatement({
          statementType: 'statement',
          startDate: null,
          endDate: null,
        })
      ">
      @if (this.statementService.loading === true) {
        Loading...
      } @else {
        View Current Statement
      }
    </button>
  </div>
  <div class="home__balance">
    <p class="home__balance__account t-heading-small">
      <!-- TODO: Verify how to display this info if more than one patient exists in the response -->
      Account number:
      <span
        class="home__balance__account__number"
        data-testid="home-account-number">
        {{ this.validateTokenService.getValidationStatus().accountNumber }}
      </span>
    </p>

    <h2 class="home__balance__due">
      <span class="t-body-large">Your total balance due: </span>
      @if (this.balanceService.getBalance().success === true) {
        <span class="t-heading-large">
          {{ this.balanceService.getBalance().balance | currency }}
        </span>
      } @else {
        <span class="t-body-large t-bold">
          Unable to retrieve balance, try again
        </span>
      }
    </h2>

    @if (this.pendingTransactionsService.getPendingTransactions() !== null) {
      @if (
        this.hasEnablePendingPaymentWarnings &&
        this.pendingTransactionsService.getPendingTransactions()
          ?.pendingTransactions === true &&
        this.balanceService.getBalance().balance !== 0
      ) {
        <app-pending-transactions></app-pending-transactions>
      }
    }
    @if (
      this.balanceService.getBalance().success === true &&
      this.balanceService.getBalance().balance !== 0
    ) {
      <div class="home__balance__buttons">
        <a
          class="home__balance__buttons__button button"
          routerLink="../bill-pay"
          >Pay in Full</a
        >

        <a
          class="home__balance__buttons__button button-secondary button-secondary--outline"
          routerLink="../payment-plan"
          >Start a Payment Plan</a
        >
      </div>
    }
  </div>

  <div class="home__charges">
    <!-- IF: Show empty state with GBO
       ELSE IF: Show empty state without GBO
       ELSE: Show open charges regardless of GBO -->
    @if (
      this.clientSettingsService.getClientSettings().billingClientType === 2 &&
      this.balanceService.getBalance().openChargesByPatients.length === 0
    ) {
      @if (
        this.balanceService.getBalance().pendingChargesByPatients.length !== 0
      ) {
        <p class="home__charges__empty-state">
          No current bill &mdash; Pending Insurance
        </p>
        <p class="home__charges__empty-state">
          You are not responsible for the balance at this time.
        </p>
      } @else {
        <p class="home__charges__empty-state">No current bill</p>
      }
    } @else if (
      this.clientSettingsService.getClientSettings().billingClientType !== 2 &&
      this.balanceService.getBalance().openChargesByPatients.length === 0
    ) {
      @if (
        this.balanceService.getBalance().pendingChargesByPatients.length !== 0
      ) {
        <p class="home__charges__empty-state">
          No current bill &mdash; Pending Insurance
        </p>
        <p class="home__charges__empty-state">
          Your claim(s) are being processed by insurance. Please allow up to 45
          days for processing. You are not responsible for the balance at this
          time.
        </p>
      } @else {
        <p class="home__charges__empty-state">
          No current bill &mdash; view History for itemized statement.
        </p>
      }
    } @else {
      <app-charges type="openChargesByPatients" [collapsable]="true" />
    }

    @if (
      this.balanceService.getBalance().pendingChargesByPatients.length !== 0
    ) {
      <details class="home__charges__collapsable-details" open>
        <summary
          class="home__charges__collapsable-details__summary t-heading-small"
          (click)="pendingDetailsClickHandler()">
          @if (
            this.clientSettingsService.getClientSettings().billingClientType ===
            2
          ) {
            Pending
          } @else {
            Pending Response from Insurance
          }
          <app-svg
            [icon]="
              pendingChargesDetailsOpen === false ? '#open-fill' : '#close-fill'
            "
            [inlineSize]="18"
            [blockSize]="18"
            fill="var(--default-theme)"></app-svg>
        </summary>
        <div class="home__charges__collapsable-details__content">
          <app-charges type="pendingChargesByPatients" />
        </div>
      </details>
    }
  </div>
  <details
    class="home__charges__collapsable-details"
    open
    *ngIf="
      this.clientSettingsService.getClientSettings().billingClientType !== 2
    ">
    <summary
      class="home__charges__collapsable-details__summary t-heading-small"
      (click)="searchDetailsClickHandler()">
      Itemized Bill and History
      <app-svg
        [icon]="
          searchChargesDetailsOpen === false ? '#open-fill' : '#close-fill'
        "
        [inlineSize]="18"
        [blockSize]="18"
        fill="var(--default-theme)"></app-svg>
    </summary>
    <div class="home__charges__collapsable-details__content">
      <app-charge-search></app-charge-search>
    </div>
  </details>
  <a [routerLink]="this.router.url" class="home__back-to-top t-body t-link"
    >Back to Top</a
  >
} @placeholder {
  <p>Loading...</p>
}
