import { AbstractControl } from '@angular/forms';

export function minDateValidator(min: string) {
  return (control: AbstractControl) => {
    const selectedDate = new Date(control.value).toISOString().split('T')[0];
    return selectedDate >= min ? null : { minDate: { requiredDate: min } };
  };
}

export function maxDateValidator(max: string) {
  return (control: AbstractControl) => {
    const selectedDate = new Date(control.value).toISOString().split('T')[0];
    return selectedDate <= max ? null : { maxDate: { requiredDate: max } };
  };
}
