import { EnvironmentInjector } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { ILogger } from '../observability/logger';
import { BrowserService } from '../services/browser/browser.service';
import { ClientSettingsService } from '../services/client-settings/client-settings.service';
import { VirtualSiteService } from '../services/virtual-site/virtual-site.service';

export const LoginHelpers = {
  loggedInWithTokenRedirect,
};

/**
 * Shared logic to handle login flow after fetching an authentication token.
 *
 * TODO: Find a better place to share this so the code can be shared still but not have to pass in services and
 * Angular objects that should be injected?
 */
async function loggedInWithTokenRedirect(
  injector: EnvironmentInjector,
  logger: ILogger
): Promise<void> {
  const activatedRoute = injector.get(ActivatedRoute);
  const router = injector.get(Router);

  const settings = await injector
    .get(ClientSettingsService)
    .fetchClientSettingsAsync();

  // If the response has no siteUrl we can't send the user to a virtual site yet. Instead send them to the
  // dashboard component to ask for billing group information.
  if (settings.siteUrl === '') {
    let route = '/app';

    if (activatedRoute.snapshot.queryParams['returnUrl']) {
      route = `${route}?returnUrl=${activatedRoute.snapshot.queryParams['returnUrl']}`;
    }

    await reroute(route, 'No Virtual Site Matched', router, logger);
    return;
  }

  // If returnUrl is provided, use it. Otherwise, default to the /app route.
  const targetRoute =
    activatedRoute.snapshot.queryParams['returnUrl'] || '/app/home';

  if (injector.get(VirtualSiteService).isSameSite(settings.siteUrl)) {
    await reroute(targetRoute, 'Login Submitted', router, logger);
    return;
  }

  const browserService = injector.get(BrowserService);
  await redirect(
    `${browserService.protocol}//${settings.siteUrl}${targetRoute}`,
    'Token Created for Different Virtual Site',
    browserService,
    logger
  );

  return;
}

async function redirect(
  url: string,
  reason: string,
  browserService: BrowserService,
  logger: ILogger
): Promise<void> {
  logger.debug('Redirected', {
    reason: reason,
    old: window.location.href,
    new: url,
  });

  await browserService.redirect(url, reason);
}

async function reroute(
  route: string,
  reason: string,
  router: Router,
  logger: ILogger
): Promise<void> {
  logger.debug('Rerouted', {
    reason: reason,
    route: route,
  });

  await router.navigateByUrl(route);
}
