<dialog
  data-testid="account-lookup-modal"
  class="modal account-lookup-modal"
  (close)="handleClose()"
  #accountLookupModal>
  <header
    class="modal__header modal__header--icon account-lookup-modal__header">
    <button
      (click)="closeModal()"
      class="modal__close-button button-inline"
      data-testid="account-lookup-modal-closeButton">
      <app-svg
        icon="#close"
        fill="var(--icon-fill)"
        [inlineSize]="24"
        [blockSize]="24"></app-svg>
      <span class="visually-hidden">close modal</span>
    </button>
    <app-svg
      class="modal__header__icon"
      icon="#account-lookup"
      fill="var(--default-theme)"
      [inlineSize]="54"
      [blockSize]="54" />
    @if (this.lookupIsSubmitted === false) {
      <h1 class="modal__header__heading">Account Lookup</h1>
      <p class="modal__header__text t-body">
        Please enter the financially responsible person's email address or phone
        number AND date of birth associated with the patient account you are
        searching for.
      </p>
    } @else {
      <h1 class="modal__header__heading">Enter Verification Code</h1>
      <p class="modal__header__text t-body">
        @if (this.accountLookupService.getAccountLookup()?.minutesToExpire) {
          If your information matches an account we have on file, we will send
          you a verification code. This code will expire in
          {{ this.accountLookupService.getAccountLookup()?.minutesToExpire }}
          minute(s).
        } @else {
          If your information matches an account we have on file, we will send
          you a verification code. This code will expire.
        }
      </p>
    }
  </header>
  <div class="modal__info account-lookup-modal__info">
    @if (this.lookupIsSubmitted === false) {
      <p class="t-body">
        MyDocBill will send you a single-use code for verification.
      </p>
      <form
        [formGroup]="accountLookupForm"
        (ngSubmit)="onAccountLookupSubmit()"
        class="account-lookup-modal__info__form">
        <legend>
          <p class="t-body t-bold">Choose how to receive your code:</p>
        </legend>
        <label class="account-lookup-modal__info__form__label form__label">
          <span class="visually-hidden">Preferred contact Method</span>
          <select
            data-testid="account-lookup-form-contactType"
            name="contactType"
            formControlName="contactType"
            required="required"
            aria-required="true"
            class="form__label__select">
            @if (this.allowEmail === true) {
              <option value="Email">Email</option>
            }
            @if (this.allowTextMessage === true) {
              <option value="Text">Text</option>
            }
          </select>
        </label>
        @if (
          accountLookupForm && accountLookupForm.value.contactType === 'Email'
        ) {
          <label class="account-lookup-modal__info__form__label form__label">
            <span class="visually-hidden">Email Address</span>
            <input
              data-testid="account-lookup-form-emailInput"
              class="form__label__input"
              formControlName="emailAddress"
              type="email"
              placeholder="hello@email.com"
              required="required"
              aria-required="true" />
          </label>
        } @else {
          <label class="account-lookup-modal__info__form__label form__label">
            <span class="visually-hidden">Cellphone Number</span>
            <input
              data-testid="account-lookup-form-textInput"
              class="form__label__input"
              formControlName="textNumber"
              type="tel"
              placeholder="5551234567"
              required="required"
              aria-required="true" />
            <span class="t-body t-form-hint"
              >10 digits with no spaces or special characters</span
            >
          </label>
        }

        <fieldset class="account-lookup-modal__info__form__fieldset">
          <legend
            class="account-lookup-modal__info__form__fieldset__legend t-body t-bold">
            Date of Birth:
          </legend>
          <div class="account-lookup-modal__info__form__fieldset__grid">
            <label
              class="account-lookup-modal__info__form__fieldset__grid__label account-lookup-modal__info__form__fieldset__grid__label--dob-month">
              <span
                class="account-lookup-modal__info__form__fieldset__grid__label__text t-form-label"
                >Month</span
              >
              <input
                class="account-lookup-modal__info__form__fieldset__grid__label__input"
                type="text"
                inputmode="numeric"
                formControlName="dobMonth"
                data-testid="account-lookup-form-dobMonth"
                placeholder="MM"
                required="required"
                pattern="^[0-9]{1,2}$"
                maxlength="2"
                aria-required="true" />
            </label>
            <label
              class="account-lookup-modal__info__form__fieldset__grid__label account-lookup-modal__info__form__fieldset__grid__label--dob-day">
              <span
                class="account-lookup-modal__info__form__fieldset__grid__label__text t-form-label"
                >Day</span
              >
              <input
                class="account-lookup-modal__info__form__fieldset__grid__label__input"
                type="text"
                inputmode="numeric"
                formControlName="dobDay"
                data-testid="account-lookup-form-dobDay"
                placeholder="DD"
                required="required"
                pattern="^[0-9]{1,2}$"
                maxlength="2"
                aria-required="true" />
            </label>
            <label
              class="account-lookup-modal__info__form__fieldset__grid__label account-lookup-modal__info__form__fieldset__grid__label--dob-year">
              <span
                class="account-lookup-modal__info__form__fieldset__grid__label__text t-form-label"
                >Year</span
              >
              <input
                class="account-lookup-modal__info__form__fieldset__grid__label__input"
                type="text"
                inputmode="numeric"
                formControlName="dobYear"
                data-testid="account-lookup-form-dobYear"
                placeholder="YYYY"
                required="required"
                pattern="^[0-9]{4}$"
                maxlength="4"
                aria-required="true" />
            </label>
          </div>
        </fieldset>

        <label
          *ngIf="this.allowReCaptcha"
          for="g-recaptcha-response-1"
          class="login__form__recaptcha">
          <span class="visually-hidden">text</span>
          <re-captcha
            id="account-lookup-recaptcha"
            formControlName="recaptchaReactive">
          </re-captcha>
        </label>

        <p
          class="account-lookup-modal__info__form__error t-body"
          data-testid="login-form-accountIdError"
          *ngIf="this.hasCodeGenError">
          {{ this.error }}
        </p>

        <button
          class="button-secondary"
          data-testid="account-lookup-form-submitButton"
          type="submit"
          [disabled]="
            !accountLookupForm.valid ||
            this.accountLookupService.loading === true
          ">
          @if (this.accountLookupService.loading === true) {
            Loading...
          } @else {
            Continue
          }
        </button>
      </form>
    } @else {
      <form
        data-testid="account-lookup-form-verificationForm"
        [formGroup]="accountVerificationForm"
        (ngSubmit)="onAccountVerificationSubmit()"
        class="account-lookup-modal__info__form">
        <label class="account-lookup-modal__info__form__label form__label">
          <span
            class="account-lookup-modal__info__form__fieldset__grid__label__text t-body"
            >Verification Code</span
          >
          <input
            data-testid="account-lookup-form-verificationInput"
            class="form__label__input"
            formControlName="verificationCode"
            placeholder="123456"
            required="required"
            aria-required="true" />
        </label>

        <p
          class="account-lookup-modal__info__form__error t-body"
          data-testid="login-form-accountIdError"
          *ngIf="this.hasCodeGenError">
          {{ this.error }}
        </p>

        <p
          class="account-lookup-modal__info__form__error t-body"
          data-testid="login-fhasAuthErrororm-accountIdError"
          *ngIf="this.hasAuthError">
          {{ this.error }}
        </p>

        <button
          class="button-secondary"
          data-testid="account-lookup-form-verificationSubmitButton"
          type="submit"
          [disabled]="
            !accountVerificationForm.valid ||
            this.authenticationByCode.loading === true
          ">
          @if (this.authenticationByCode.loading === true) {
            Loading...
          } @else {
            Sign In
          }
        </button>

        <button
          class="account-lookup-modal__info__form__fieldset__grid__button-inline button-inline t-body"
          data-testid="account-lookup-form-verificationReSubmitButton"
          type="button"
          (click)="onAccountLookupResubmit()"
          [disabled]="
            !accountLookupForm.valid ||
            this.accountLookupService.loading === true
          ">
          @if (this.accountLookupService.loading === true) {
            Sending...
          } @else {
            Resend Code
          }
        </button>
      </form>
    }
  </div>
</dialog>
