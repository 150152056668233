import { HttpClient, HttpResponse } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';

import { environment } from '../../../environments/environment';
import { AuthService } from '../auth/auth.service';

export interface validateTokenResponse {
  success: boolean;
  token: string;
  accountNumber: string;
  needsBillingGroup: boolean;
}
@Injectable({
  providedIn: 'root',
})
export class ValidateTokenService {
  authService = inject(AuthService);

  endPointBase: string = '/ValidateToken';

  private response: validateTokenResponse = {
    success: false,
    token: '',
    accountNumber: '',
    needsBillingGroup: false,
  };

  tempDate!: Date;
  tempDateHours!: string;
  tempDateMinutes!: string;

  constructor(private http: HttpClient) {}

  fetchValidateToken() {
    if (!this.authService.isAuthenticated()) {
      return;
    }

    this.http
      .post<validateTokenResponse>(
        environment.apiUrl + this.endPointBase,
        {},
        {
          observe: 'response',
        }
      )
      .subscribe({
        next: (res: HttpResponse<validateTokenResponse>) => {
          if (res.status === 200 && res.body) {
            this.response = res.body;

            if (res.body.success && res.body.token) {
              localStorage.setItem('token', res.body.token);
              this.tempDate = new Date();
              this.tempDateHours = (
                (this.tempDate.getHours() + 24) % 12 || 12
              ).toString();
              this.tempDateMinutes = this.tempDate
                .getUTCMinutes()
                .toString()
                .padStart(2, '0');
            } else if (!res.body.success) {
              localStorage.removeItem('token');
              void this.authService.logout();
            }
          }
        },
      });
  }

  getValidationStatus() {
    // Return data stored in the service
    return this.response;
  }
}
