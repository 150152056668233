<dialog
  data-testid="billing-group-modal"
  class="modal modal--full-screen billing-group-modal"
  #billingGroupModal>
  <header class="modal__header billing-group-modal__header">
    <h1 class="modal__header__heading">Select a site</h1>
    <p class="modal__header__text t-body">
      Your account belongs to multiple sites. Please select one to continue.
    </p>
  </header>
  <div class="modal__info billing-group-modal__info">
    @defer (when this.billingGroupSelectionService.complete === true) {
      @if (billingGroups && billingGroups.length > 0) {
        @for (
          billingGroupEntry of billingGroups;
          track billingGroupEntry;
          let i = $index
        ) {
          <button
            (click)="this.onClick(billingGroupEntry.billingGroupId)"
            [attr.data-testid]="'billing-group-modal-button' + i"
            class="billing-group-modal__info__button button-inline">
            <span>{{ billingGroupEntry.billingGroupName }}</span>
            <app-svg
              [blockSize]="20"
              [inlineSize]="20"
              [icon]="'#right-chevron'"
              fill="var(--global-theme)" />
          </button>
        }
      } @else {
        <p>There was an error loading billing groups</p>
      }
    } @placeholder (minimum 500ms) {
      <p class="billing-group-modal__info__button">Loading...</p>
    }
  </div>
</dialog>
