import { HttpInterceptorFn } from '@angular/common/http';

import { authInterceptor } from './auth/auth.interceptor';
import { errorInterceptor } from './error/error.interceptor';

// The order of interceptors is important. The first interceptor added is the first to execute
// on the way out and the last to execute on the way back in. This ErrorHandlerInterceptorService
// interceptor MUST be added first to catch and log possible exceptions from other middlewares.
// See https://angular.io/guide/http-intercept-requests-and-responses#interceptor-order
export const INTERCEPTORS: HttpInterceptorFn[] = [
  errorInterceptor,
  authInterceptor,
];
