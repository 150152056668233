import { HttpClient, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';

import { environment } from '../../../environments/environment';

export interface demographicsResponse {
  isGuarantor: boolean;
  personId: number;
  firstName: string;
  middleInitial: string;
  lastName: string;
  dateOfBirth: Date;
  gender: string;
  address1: string;
  address2: string;
  city: string;
  state: string;
  zip: string;
  emailAddress: string;
  emailAddressIndicator: string;
  mobilePhone: string;
  mobilePhoneIndicator: string;
  homePhone: string;
  workPhone: string;
  disallowPatientPortalInformation: boolean;
}

@Injectable({
  providedIn: 'root',
})
export class DemographicsService {
  endPointBase: string = '/Demographics';

  response: demographicsResponse[] = [
    {
      isGuarantor: false,
      personId: 0,
      firstName: '',
      middleInitial: '',
      lastName: '',
      dateOfBirth: new Date(),
      gender: '',
      address1: '',
      address2: '',
      city: '',
      state: '',
      zip: '',
      emailAddress: '',
      emailAddressIndicator: '',
      mobilePhone: '',
      mobilePhoneIndicator: '',
      homePhone: '',
      workPhone: '',
      disallowPatientPortalInformation: false,
    },
  ];

  complete: boolean = false;

  responseSubject = new Subject<demographicsResponse[]>();
  response$ = this.responseSubject.asObservable();

  constructor(private http: HttpClient) {}

  fetchDemographics() {
    this.http
      .post<
        demographicsResponse[]
      >(environment.apiUrl + this.endPointBase, {}, { observe: 'response' })
      .subscribe({
        next: (res: HttpResponse<demographicsResponse[]>) => {
          if (res != null && res.status == 200 && res.body) {
            this.response = res.body;
            this.responseSubject.next(res.body);
            this.complete = true;
          }
        },
      });
  }

  patchDemographicInfo(
    formData: unknown
  ): Observable<HttpResponse<demographicsResponse>> {
    return this.http.patch<demographicsResponse>(
      environment.apiUrl + this.endPointBase,
      formData,
      { observe: 'response' }
    );
  }

  getDemographicsInfo() {
    return this.response; // Return data stored in the service
  }
}
