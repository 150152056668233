import { inject, Injectable } from '@angular/core';

import { BalanceService } from '../../../services/balance/balance.service';
import { ClientSettingsService } from '../../../services/client-settings/client-settings.service';
import { PaperlessBillingService } from '../../../services/paperless-billing/paperless-billing.service';
import { PendingTransactionsService } from '../../../services/pending-transactions/pending-transactions.service';
import { paymentSuccessData } from '../../payment/services/payment-service.service';
import { paymentPlanFormObject } from '../payment-plan-selection/payment-plan-selection.component';

class DefaultPaymentPlan {
  paymentAmountPerMonth: number;
  numberOfPayments: number;

  constructor(amount: number, numberOfPayments: number) {
    this.paymentAmountPerMonth = amount;
    this.numberOfPayments = numberOfPayments;
  }
}

export interface PaymentSelectionObject {
  paymentPlan: number | string;
  startDate: string;
  emailAddress: string;
  additionalFields: {
    endDate: string;
    paymentAmount: number | string;
    numberOfPayments: number | string;
    guarantorBalance: number;
  };
}

@Injectable({
  providedIn: 'root',
})
export class PaymentPlanServiceService {
  // 1: Payment Plan Selection, first page of the flow
  // 2: Payment Plan Information, second page of the flow
  // 3: Payment Plan Confirmation, third and final page of the flow
  paymentLocation: number = 1;
  paymentAmount!: string | undefined;
  paymentSuccessData!: undefined | paymentSuccessData;

  // Set Defaults, never touch them
  readonly paymentSelectionsDefaults: Readonly<PaymentSelectionObject> = {
    paymentPlan: 0,
    emailAddress: '',
    startDate: '',
    additionalFields: {
      endDate: '',
      paymentAmount: 0,
      numberOfPayments: 0,
      guarantorBalance: 0,
    },
  };

  paymentSelections: PaymentSelectionObject = {
    ...this.paymentSelectionsDefaults,
  };

  paymentSelectionsCopy!: PaymentSelectionObject | undefined;

  // Vars for payment plan options
  hasGeneratedPlanOptions = false;
  guarantorBalance!: number;
  defaultPlans: DefaultPaymentPlan[] = [];

  singleInterval!: number;
  hasProgressedToPaymentInformation = false;

  // Injects
  balanceService = inject(BalanceService);
  pendingTransactionsService = inject(PendingTransactionsService);
  paperlessBillingService = inject(PaperlessBillingService);
  clientSettingsService = inject(ClientSettingsService);

  constructor() {}

  nextPage() {
    this.paymentLocation++;
  }

  previousPage() {
    this.paymentLocation--;
  }

  copySelections() {
    this.paymentSelectionsCopy = { ...this.paymentSelections };
  }

  resetPaymentPlanFlow() {
    this.paymentAmount = undefined;
    this.paymentLocation = 1;
    this.paymentSuccessData = undefined;
    this.paymentSelections = this.paymentSelectionsDefaults;
    this.paymentSelectionsCopy = undefined;
    this.hasGeneratedPlanOptions = false;
  }

  fetchData() {
    this.balanceService.complete = false;
    this.pendingTransactionsService.complete = false;
    this.paperlessBillingService.complete = false;
    this.balanceService.fetchBalance();
    this.pendingTransactionsService.fetchPendingTransactions();
    this.paperlessBillingService.fetchPaperlessBillingInfo();
  }

  saveFormValues(
    formObject: paymentPlanFormObject,
    isPredefinedPlanSelected: boolean
  ) {
    this.paymentSelections = { ...formObject };
    if (isPredefinedPlanSelected) {
      this.paymentSelections.additionalFields.paymentAmount =
        this.defaultPlans[formObject.paymentPlan].paymentAmountPerMonth;
      this.paymentSelections.additionalFields.numberOfPayments =
        this.defaultPlans[formObject.paymentPlan].numberOfPayments;
    }
    this.hasProgressedToPaymentInformation = true;
  }

  setPaymentPlanOptions(
    numberOfOptions: number,
    maxNumberOfPayments: number,
    minimumPaymentAmount: number
  ) {
    this.guarantorBalance = this.balanceService.getBalance().balance;
    this.defaultPlans = [];

    const maxPaymentsWithMinimumAmount = Math.floor(
      this.guarantorBalance / minimumPaymentAmount
    );

    if (maxPaymentsWithMinimumAmount > 1) {
      this.hasGeneratedPlanOptions = true;

      if (
        maxPaymentsWithMinimumAmount / numberOfOptions >
        maxNumberOfPayments
      ) {
        this.calculateWithMaxNumberOfPaymentAllowed(
          numberOfOptions,
          maxNumberOfPayments,
          minimumPaymentAmount
        );
      } else {
        this.calculateWithMinimumAllowedAmount(
          numberOfOptions,
          maxNumberOfPayments,
          minimumPaymentAmount
        );
      }
      this.paymentSelections.paymentPlan = 0;
    }
  }

  calculateWithMinimumAllowedAmount(
    numberOfOptions: number,
    maxPaymentsWithMinimumAmount: number,
    minimumPaymentAmount: number
  ) {
    this.defaultPlans = [];
    let numberOfPayments;
    this.singleInterval =
      maxPaymentsWithMinimumAmount <= numberOfOptions
        ? 1
        : Math.floor(maxPaymentsWithMinimumAmount / numberOfOptions);
    for (let option = 1; option <= numberOfOptions; option++) {
      numberOfPayments = this.singleInterval * option;

      const rawPaymentAmount: number =
        (this.guarantorBalance / numberOfPayments) * 100;
      const paymentAmount: number =
        (this.clientSettingsService.getClientSettings().usePaymentRounding
          ? Math.floor(rawPaymentAmount)
          : Math.ceil(rawPaymentAmount)) / 100;

      if (paymentAmount >= minimumPaymentAmount && numberOfPayments > 1) {
        this.defaultPlans.push(
          new DefaultPaymentPlan(paymentAmount, numberOfPayments)
        );
      }
    }
  }

  calculateWithMaxNumberOfPaymentAllowed(
    numberOfOptions: number,
    maxNumberOfPayments: number,
    minimumPaymentAmount: number
  ) {
    this.defaultPlans = [];
    let numberOfPayments;

    this.singleInterval =
      maxNumberOfPayments <= numberOfOptions
        ? 1
        : Math.floor(maxNumberOfPayments / numberOfOptions);

    for (let option = 1; option <= numberOfOptions; option++) {
      numberOfPayments = this.singleInterval * option;
      const rawPaymentAmount: number =
        (this.guarantorBalance / numberOfPayments) * 100;
      const paymentAmount: number =
        (this.clientSettingsService.getClientSettings().usePaymentRounding
          ? Math.floor(rawPaymentAmount)
          : Math.ceil(rawPaymentAmount)) / 100;

      if (paymentAmount >= minimumPaymentAmount && numberOfPayments > 1) {
        this.defaultPlans.push(
          new DefaultPaymentPlan(paymentAmount, numberOfPayments)
        );
      }
    }
  }
}
