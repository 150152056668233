<details open>
  <summary>Dev Panel</summary>

  <div class="details__content">
    <button
      (click)="onLogoutClick()"
      *ngIf="this.authService.isAuthenticated()">
      Logout
    </button>

    <button
      (click)="onValidateClick()"
      *ngIf="this.authService.isAuthenticated()">
      @if (this.validateRunning === false) {
        Start Auto-validate
      } @else {
        Stop Validating
      }
    </button>

    @if (this.validateRunning === true) {
      <span *ngIf="this.authService.isAuthenticated()">
        Auto-validating stopping 1 hour from:
        <span class="t-bold">
          {{ this.validateRunningDate }}
        </span>
      </span>
    }

    <div
      class="auth-info {{
        this.authService.isAuthenticated() && 'is-authenticated'
      }}">
      Authenticated:
      <span class="auth-info__state t-bold">
        {{ this.authService.isAuthenticated() }}
      </span>
    </div>

    <div class="auth-info">
      @if (this.validateService.getValidationStatus().success) {
        <span>
          Validated last at:
          <span class="t-bold">
            {{
              this.validateService.tempDateHours +
                ':' +
                this.validateService.tempDateMinutes
            }}
          </span>
        </span>
      }
    </div>
  </div>
</details>
