import { inject } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivateFn,
  GuardResult,
  Router,
  RouterStateSnapshot,
  UrlCreationOptions,
} from '@angular/router';

import { AuthService } from '../../../services/auth/auth.service';

export const isAuthenticated: CanActivateFn = (
  _next: ActivatedRouteSnapshot,
  state: RouterStateSnapshot
): GuardResult => {
  const authService = inject(AuthService);
  const router = inject(Router);

  if (authService.isAuthenticated()) {
    return true;
  }

  if (authService.isPartiallyAuthenticated()) {
    return router.createUrlTree(['/app'], {
      queryParams: {
        returnUrl: state.url,
      },
    } as UrlCreationOptions);
  }

  return router.createUrlTree(['/login'], {
    queryParams: {
      returnUrl: state.url,
    },
  } as UrlCreationOptions);
};
