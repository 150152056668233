@defer (when this.demographicsService.complete === true) {
  <h1 class="demographics__header t-heading">Profile</h1>
  <div class="demographics__radio-group">
    <label class="demographics__radio-group__label t-body">
      <span>Guarantor</span>
      <input
        class="demographics__radio-group__label__input"
        type="radio"
        checked="checked"
        name="demographics_person"
        (click)="radioSelectGuarantor()" />
    </label>
    <label class="demographics__radio-group__label t-body">
      <span>Patients</span>
      <input
        class="demographics__radio-group__label__input"
        type="radio"
        name="demographics_person"
        (click)="radioSelectPatients()" />
    </label>
  </div>
  <p class="demographics__required-note">* - fields are marked required</p>
  @if (this.type === 'guarantor' && this.guarantorForm) {
    <form
      [formGroup]="guarantorForm"
      class="demographics__form"
      (ngSubmit)="patchDemographics()">
      <div class="deomgraphics__form__controls">
        <label
          class="demographics__form__controls__label demographics__form__controls__label--name t-form-label t-form-label--large form__label">
          <span class="demographics__form__controls__label__text">Name</span>
          <input
            class="demographics__form__controls__label__input demographics__form__controls__label__input--immutable form__label__input form__label__input--large"
            type="text"
            data-testid="guarantor-form-name"
            required="required"
            formControlName="name"
            readonly />
        </label>
        <label
          class="demographics__form__controls__label demographics__form__controls__label--address1 t-form-label t-form-label--large form__label">
          <span class="demographics__form__controls__label__text"
            ><span class="demographics__form__controls__labels__text__required"
              >*</span
            >
            Address</span
          >
          <input
            class="demographics__form__controls__label__input form__label__input form__label__input--large"
            type="text"
            data-testid="guarantor-form-address1"
            required="required"
            formControlName="address1" />
        </label>
        <label
          class="demographics__form__controls__label demographics__form__controls__label--address2 t-form-label t-form-label--large form__label">
          <span class="demographics__form__controls__label__text"
            >Address Line 2</span
          >
          <input
            class="demographics__form__controls__label__input form__label__input form__label__input--large"
            type="text"
            data-testid="guarantor-form-address2"
            formControlName="address2" />
        </label>
        <label
          class="demographics__form__controls__label demographics__form__controls__label--zip t-form-label t-form-label--large form__label">
          <span class="demographics__form__controls__label__text">
            <span class="demographics__form__controls__labels__text__required"
              >*</span
            >
            Zip</span
          >
          <input
            class="demographics__form__controls__label__input form__label__input form__label__input--large"
            type="text"
            inputmode="numeric"
            data-testid="guarantor-form-zip"
            required="required"
            maxlength="5"
            pattern="[0-9]{5}$"
            formControlName="zip"
            (input)="updateCityState()" />
        </label>
        <label
          class="demographics__form__controls__label demographics__form__controls__label--city t-form-label t-form-label--large form__label">
          <span class="demographics__form__controls__label__text">City</span>
          <input
            class="demographics__form__controls__label__input demographics__form__controls__label__input--immutable form__label__input form__label__input--large"
            type="text"
            data-testid="guarantor-form-city"
            required="required"
            formControlName="city"
            readonly />
        </label>
        <label
          class="demographics__form__controls__label demographics__form__controls__label--state t-form-label t-form-label--large form__label">
          <span class="demographics__form__controls__label__text">State</span>
          <input
            class="demographics__form__controls__label__input demographics__form__controls__label__input--state form__label__input form__label__input--large"
            type="text"
            data-testid="guarantor-form-state"
            required="required"
            formControlName="state"
            readonly />
        </label>
        <label
          class="demographics__form__controls__label demographics__form__controls__label--checkbox t-form-label t-form-label--large form__label">
          <input
            formControlName="verifyAddress"
            type="checkbox"
            data-testid="paper-form-verifyAddress"
            (click)="showUspsModal()"
            [checked]="this.guarantorForm.valid"
            required="required" />
          <span class="demographics__form__controls__label__text">
            <span class="demographics__form__controls__labels__text__required"
              >*</span
            >
            I have verified that my information is correct
          </span>
        </label>
        <label
          class="demographics__form__controls__label demographics__form__controls__label--email t-form-label t-form-label--large form__label">
          <span class="demographics__form__controls__label__text">
            <span class="demographics__form__controls__labels__text__required"
              >*</span
            >
            Email</span
          >
          <input
            class="demographics__form__controls__label__input form__label__input form__label__input--large"
            type="text"
            type="email"
            data-testid="guarantor-form-email"
            required="required"
            formControlName="email" />
        </label>
        <label
          class="demographics__form__controls__label demographics__form__controls__label--mobile t-form-label t-form-label--large form__label">
          <span class="demographics__form__controls__label__text"
            ><span class="demographics__form__controls__labels__text__required"
              >*</span
            >
            Mobile Phone</span
          >
          <input
            class="demographics__form__controls__label__input form__label__input form__label__input--large"
            type="text"
            inputmode="numeric"
            placeholder="xxx-xxx-xxxx"
            data-testid="guarantor-form-mobile"
            required="required"
            maxlength="12"
            pattern="^([0-9]{3}-[0-9]{3}-[0-9]{4})$"
            formControlName="mobilePhone" />
        </label>
        <label
          class="demographics__form__controls__label demographics__form__controls__label--homePhone t-form-label t-form-label--large form__label">
          <span class="demographics__form__controls__label__text"
            >Home Phone</span
          >
          <input
            class="demographics__form__controls__label__input form__label__input form__label__input--large"
            type="text"
            inputmode="numeric"
            placeholder="xxx-xxx-xxxx"
            data-testid="guarantor-form-homePhone"
            maxlength="12"
            pattern="^([0-9]{3}-[0-9]{3}-[0-9]{4})$"
            formControlName="homePhone" />
        </label>
        <label
          class="demographics__form__controls__label demographics__form__controls__label--work t-form-label t-form-label--large form__label">
          <span class="demographics__form__controls__label__text"
            >Work Phone</span
          >
          <input
            class="demographics__form__controls__label__input form__label__input form__label__input--large"
            type="text"
            inputmode="numeric"
            placeholder="xxx-xxx-xxxx"
            data-testid="guarantor-form-work"
            maxlength="12"
            pattern="^([0-9]{3}-[0-9]{3}-[0-9]{4})$"
            formControlName="workPhone" />
        </label>
      </div>
      <div class="demographics__form__errors">
        <p
          class="demographics__form__errors__error"
          data-testid="demographics-form-error-badMobileFormat"
          *ngIf="
            guarantorForm.get('mobilePhone')?.getError('pattern') ||
            guarantorForm.get('workPhone')?.getError('pattern') ||
            guarantorForm.get('homePhone')?.getError('pattern')
          ">
          {{ this.formErrors.badMobileFormat }}
        </p>
        <p
          class="demographics__form__errors__error"
          data-testid="demographics-form-error-badMobile"
          *ngIf="
            this.isBadMobile &&
            guarantorForm.get('mobilePhone')?.getError('badMobile')
          ">
          {{ this.formErrors.badMobile }}
        </p>
        <p
          class="demographics__form__errors__error"
          data-testid="demographics-form-error-badEmail"
          *ngIf="
            this.isBadEmail && guarantorForm.get('email')?.getError('badEmail')
          ">
          {{ this.formErrors.badEmail }}
        </p>
      </div>
      <button
        class="demographics__form__disable-account__button button-secondary"
        data-testid=""
        data-testid="charge-search-submit-button"
        (click)="disableDemographics()">
        <span class="t-body--large">Disable Online Account</span>
      </button>
      <p class="demographics__form__tos t-body">
        By clicking below, I'm giving express consent to receive, at the number
        above, automated text messages and prerecorded call from
        {{ this.clientSettingsService.getClientSettings().companyNameDisplay }}
        and it's agents and affiliates, to manage my appointments and get
        information about bills and paying for services, for all current and
        future transactions with
        {{ this.clientSettingsService.getClientSettings().companyNameDisplay }}.
        Messages and data rates may apply. Purchase not required. Reply STOP to
        any text message to opt-out. By opting into paperless billing, I convey
        that I agree to the
        <a
          [href]="this.termsOfUseUrl"
          class="t-link"
          target="_blank"
          data-testid="login-terms-of-use-link">
          Terms of Use
          <span class="visually-hidden">(opens in a new window)</span>
        </a>
      </p>
      <button
        class="demographics__form__cancel__button button-secondary"
        (click)="cancelForm()"
        data-testid=""
        data-testid="charge-search-submit-button">
        <span class="t-body--large">Cancel</span>
      </button>
      <button
        class="demographics__form__submit__button button"
        type="submit"
        [disabled]="!guarantorForm.valid"
        data-testid=""
        data-testid="charge-search-submit-button">
        <span class="t-body--large">Submit</span>
      </button>
    </form>
    @if (this.needAddressVerify === true) {
      <app-usps-modal
        (uspsAddressEmitter)="uspsAddressEmitter($event)"
        (uspsAddressCancelEmitter)="uspsAddressCancelEmitter($event)"
        [inputAddress1]="this.guarantorForm.value.address1"
        [inputAddress2]="this.guarantorForm.value.address2"
        [inputZip]="this.guarantorForm.value.zip"
        [inputCity]="this.guarantorForm.value.city"
        [inputState]="this.guarantorForm.value.state"
        [addressVerification]="this.needAddressVerify">
      </app-usps-modal>
    }
  } @else if (this.type === 'patients' && this.patientsForm) {
    <div class="demographics__patients__selector">
      <label
        class="patients__form__controls__label t-form-label t-form-label--large form__label">
        <span class="patients__form__controls__label__text"
          >Select a patient</span
        >
        <select
          class="demographics__patients__selector__select form__label__select form__label__select--large"
          #patient
          data-testid="patients-selector"
          [(ngModel)]="selectedPatientId"
          (change)="displaySelectedPatient(selectedPatientId)">
          <option value="" disabled selected>Choose one...</option>
          <option
            *ngFor="let patient of this.patients"
            [ngValue]="patient.personId">
            {{ patient.firstName + ' ' + patient.lastName }}
          </option>
        </select>
      </label>
    </div>
    @if (this.isPatientSelected) {
      <form
        [formGroup]="patientsForm"
        (ngSubmit)="patchPatients()"
        class="patients__form">
        <div class="patients__form__controls">
          <label
            class="patients__form__controls__label patients__form__controls__label--firstName t-form-label t-form-label--large form__label">
            <span class="patients__form__controls__label__text"
              ><span class="patients__form__controls__label__required">*</span>
              First Name</span
            >
            <input
              class="patients__form__controls__label__input form__label__input form__label__input--large"
              type="text"
              data-testid="patients-form-name"
              required="required"
              formControlName="firstName" />
          </label>
          <label
            class="patients__form__controls__label patients__form__controls__label--middleInitial t-form-label t-form-label--large form__label">
            <span class="patients__form__controls__label__text"
              >Middle Initial</span
            >
            <input
              class="patients__form__controls__label__input form__label__input form__label__input--large"
              type="text"
              data-testid="patients-form-middle"
              formControlName="middleInitial" />
          </label>
          <label
            class="patients__form__controls__label patients__form__controls__label--lastName t-form-label t-form-label--large form__label">
            <span class="patients__form__controls__label__text"
              ><span class="patients__form__controls__label__required">*</span>
              Last Name</span
            >
            <input
              class="patients__form__controls__label__input form__label__input form__label__input--large"
              type="text"
              data-testid="patients-form-lastName"
              required="required"
              formControlName="lastName" />
          </label>
          <label
            class="patients__form__controls__label patients__form__controls__label--dob t-form-label t-form-label--large form__label">
            <span class="patients__form__controls__label__text"
              ><span class="patients__form__controls__label__required">*</span>
              Date of Birth (DD-MM-YYYY)</span
            >
            <input
              class="patients__form__controls__label__input form__label__input form__label__input--large"
              type="text"
              data-testid="patients-form-dateOfBirth"
              required="required"
              formControlName="dateOfBirth" />
          </label>
          <label
            class="patients__form__controls__label patients__form__controls__label--gender t-form-label t-form-label--large form__label">
            <span class="patients__form__controls__label__text"
              ><span class="patients__form__controls__label__required">*</span>
              Sex</span
            >
            <select
              class="patients__form__controls__label__select form__label__select form__label__select--large"
              #gender
              data-testid="patients-form-gender"
              required="required"
              formControlName="gender"
              (change)="selectGender(gender.value)">
              <option
                *ngFor="let gender of this.providedGenders"
                [ngValue]="gender">
                {{ gender }}
              </option>
            </select>
          </label>
        </div>
        <button
          class="patients__form__disable-account__button button-secondary"
          data-testid=""
          data-testid="charge-search-submit-button"
          (click)="disablePatient()">
          <span class="t-body--large">Disable Online Account</span>
        </button>
        <button
          class="patients__form__cancel__button button-secondary"
          (click)="cancelForm()"
          data-testid=""
          data-testid="charge-search-submit-button">
          <span class="t-body--large">Cancel</span>
        </button>
        <button
          class="patients__form__submit__button button"
          type="submit"
          [disabled]="!patientsForm.valid"
          data-testid=""
          data-testid="charge-search-submit-button">
          <span class="t-body--large">Submit</span>
        </button>
      </form>
    }
  }
} @placeholder {
  <p>Loading...</p>
}
