import { A11yModule, LiveAnnouncer } from '@angular/cdk/a11y';
import { CommonModule } from '@angular/common';
import {
  Component,
  EnvironmentInjector,
  Inject,
  OnInit,
  inject,
} from '@angular/core';
import { ReactiveFormsModule, Validators, FormBuilder } from '@angular/forms';
import { RouterLink, RouterOutlet } from '@angular/router';
import { RecaptchaModule, RecaptchaFormsModule } from 'ng-recaptcha';

import { verifyFeatureToggle } from '../helpers/helpers';
import { LoginHelpers } from '../helpers/login';
import { ILogger } from '../observability/logger';
import { LOGGER_FACTORY, LoggerFactory } from '../observability/provider';
import { AuthService } from '../services/auth/auth.service';
import {
  clientSettingsResponse,
  ClientSettingsService,
  featureToggles,
} from '../services/client-settings/client-settings.service';
import { IdleService } from '../services/idle/idle.service';
import { VirtualSiteType } from '../services/virtual-site/virtual-site';
import { VirtualSiteService } from '../services/virtual-site/virtual-site.service';
import { SvgComponent } from '../svg/svg.component';

@Component({
  selector: 'app-login',
  standalone: true,
  imports: [
    ReactiveFormsModule,
    CommonModule,
    A11yModule,
    SvgComponent,
    RecaptchaFormsModule,
    RecaptchaModule,
    RouterOutlet,
    RouterLink,
  ],
  templateUrl: './login.component.html',
  styleUrl: './login.component.scss',
})
export class LoginComponent implements OnInit {
  private readonly logger: ILogger;
  hasAuthError: boolean = false;
  idleMessage: string | null = null;
  featureToggles: featureToggles[] | undefined;
  allowReCaptcha: boolean = true;
  isGlobalSite: boolean;

  // Create Form Group for our User Input
  loginForm = this.fb.group({
    accountId: ['', [Validators.required, Validators.pattern('[0-9]+')]],
    clientId: ['', [Validators.required, Validators.pattern('[a-zA-Z0-9]+')]],
    billingGroup: ['', [Validators.pattern('[a-zA-Z0-9]*')]],
    dobMonth: ['', [Validators.required, Validators.pattern('^[0-9]{1,2}$')]],
    dobDay: ['', [Validators.required, Validators.pattern('^[0-9]{1,2}$')]],
    dobYear: ['', [Validators.required, Validators.pattern('^[0-9]{4}$')]],
    recaptchaReactive: [null],
  });

  clientSettingsService = inject(ClientSettingsService);
  idleService = inject(IdleService);
  virtualSiteService = inject(VirtualSiteService);

  termsOfUseUrl: string =
    this.clientSettingsService.getClientSettings().termsOfUseUrl;

  readOnlyBillingGroup = false;
  readOnlyClientId = false;

  public loginError!: string;

  constructor(
    private injector: EnvironmentInjector,
    private authService: AuthService,
    private fb: FormBuilder,
    private liveAnnouncer: LiveAnnouncer,
    @Inject(LOGGER_FACTORY) loggerFactory: LoggerFactory
  ) {
    this.logger = loggerFactory('LoginComponent');
    this.isGlobalSite =
      this.virtualSiteService.site.type !== VirtualSiteType.Global;
  }

  formErrors = {
    accountIdError: 'Account ID is required and can only be numbers',
    clientIdError: 'Client ID is required and can only be numbers or letters',
    billingGroupError:
      'Group ID is not required but can only be numbers or letters',
    dobMonthError: 'Month of Birth is required and must be two numbers',
    dobDayError: 'Day of Birth is required and must be two numbers',
    dobYearError: 'Year of Birth is required and must be four numbers',
    recaptchaError: 'ReCaptcha is required and must successfully be filled out',
  };

  authError = '';
  ngOnInit(): void {
    this.idleMessage = this.idleService.takeAndForget();

    if (this.idleMessage) {
      this.liveAnnouncer.announce(this.idleMessage);
    }

    this.clientSettingsService.response$.subscribe(
      (res: clientSettingsResponse) => {
        if (res) {
          res.termsOfUseUrl && (this.termsOfUseUrl = res.termsOfUseUrl);
          this.featureToggles =
            this.clientSettingsService.getClientSettings().featureToggles;
          this.allowReCaptcha = verifyFeatureToggle(
            'AllowRecaptchaResponse',
            this.featureToggles
          );
          this.loginForm.get('clientId')?.valueChanges.subscribe(() => {
            if (this.allowReCaptcha) {
              this.loginForm.controls['recaptchaReactive'].setValidators([
                Validators.required,
              ]);
            } else {
              this.loginForm.controls['recaptchaReactive'].clearValidators();
            }
            this.loginForm.controls[
              'recaptchaReactive'
            ].updateValueAndValidity();
          });
          if (
            this.clientSettingsService.getClientSettings().billingGroupId &&
            this.clientSettingsService.getClientSettings().billingGroupId !==
              '0'
          ) {
            this.loginForm
              .get('billingGroup')
              ?.setValue(
                this.clientSettingsService
                  .getClientSettings()
                  .billingGroupId.toString()
              );
            this.readOnlyBillingGroup = true;
          }

          if (this.clientSettingsService.getClientSettings().clientId) {
            this.loginForm
              .get('clientId')
              ?.setValue(
                this.clientSettingsService.getClientSettings().clientId
              );
            this.readOnlyClientId = true;
          }
        }
      }
    );
  }

  axErrorHandler(event: Event, errorId: string) {
    const errorsTest = Object.entries(this.formErrors);
    const eventTarget = event.target as HTMLInputElement;
    const eventTargetControlName = eventTarget.getAttribute('formControlName');

    errorsTest.map(([key, val]) => {
      if (errorId === key) {
        if (
          eventTargetControlName &&
          this.loginForm.get(eventTargetControlName)?.touched &&
          this.loginForm.get(eventTargetControlName)?.errors
        ) {
          this.liveAnnouncer.announce(val);
        }
      }
    });
  }

  async onSubmit() {
    const result = await this.authService.login(
      Number(this.loginForm.value.accountId),
      this.loginForm.value.clientId!,
      this.loginForm.value.billingGroup!,
      Number(this.loginForm.value.dobMonth),
      Number(this.loginForm.value.dobDay),
      Number(this.loginForm.value.dobYear),
      this.virtualSiteService.site.url
    );

    if (result.isSuccess) {
      this.hasAuthError = false;
      this.authError = '';

      await LoginHelpers.loggedInWithTokenRedirect(this.injector, this.logger);

      return;
    }

    this.hasAuthError = true;
    this.authError =
      result.error ?? 'There was an error logging you in, please try again';

    await this.liveAnnouncer.announce(this.authError);
  }
}
