import { CommonModule } from '@angular/common';
import { Component, OnInit, inject } from '@angular/core';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';

import { ClientSettingsService } from '../services/client-settings/client-settings.service';
import { FeedbackService } from '../services/feedback/feedback.service';

@Component({
  selector: 'app-feedback',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './feedback.component.html',
  styleUrl: './feedback.component.scss',
})
export class FeedbackComponent implements OnInit {
  surveyUrl: SafeUrl | undefined = '';
  clientId: string = '';

  feedbackService = inject(FeedbackService);
  clientSettingsService = inject(ClientSettingsService);
  sanitizer = inject(DomSanitizer);

  ngOnInit() {
    this.feedbackService.fetchFeedbackUrl({
      clientId: this.clientSettingsService.getClientSettings().clientId,
    });

    this.feedbackService.response$.subscribe({
      next: () => {
        console.log(this.feedbackService.getFeedbackSurveyInfo());
        this.surveyUrl = this.sanitizer.bypassSecurityTrustResourceUrl(
          this.feedbackService.getFeedbackSurveyInfo().surveyUrl
        );
      },
    });
  }
}
