import { CommonModule } from '@angular/common';
import { Component, inject, OnInit } from '@angular/core';

import { ClientSettingsService } from '../services/client-settings/client-settings.service';
import { faqResponse, FaqService } from '../services/faq/faq.service';

@Component({
  selector: 'app-support',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './support.component.html',
  styleUrl: './support.component.scss',
})
export class SupportComponent implements OnInit {
  clientSettingsService = inject(ClientSettingsService);
  faqService = inject(FaqService);

  questions: faqResponse[] | null = null;

  ngOnInit() {
    this.faqService.fetchFaq();

    this.faqService.response$.subscribe({
      next: (res: faqResponse[]) => {
        if (res !== undefined && res.length > 0) {
          this.questions = res;
        }
      },
    });
  }
}
