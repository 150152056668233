import { inject, Injectable } from '@angular/core';
import { Observable, Subject, interval, Subscription, throttle } from 'rxjs';

import { ValidateTokenService } from '../validate-token/validate-token.service';

@Injectable({
  providedIn: 'root',
})
export class IdleService {
  validateTokenService = inject(ValidateTokenService);
  public idleSubject = new Subject<boolean>();
  private timeout = 900; //seconds - 900 for 15mins
  private lastActivity?: Date;
  private idleCheckInterval = 60; //seconds
  private reValidateIfActiveWindow = 45; //seconds
  idleSubscription?: Subscription;
  idleStatus = false;

  constructor() {
    this.idleSubject.subscribe((res: boolean) => {
      if (res) {
        this.idleStatus;
      }
    });
  }

  private idleError =
    'Your session has expired due to inactivity and you have been automatically logged out.';

  get idleState(): Observable<boolean> {
    return this.idleSubject.asObservable();
  }

  startWatching() {
    this.resetTimer();

    this.idleSubscription = interval(this.idleCheckInterval * 1000)
      .pipe(throttle(() => interval(1000)))
      .subscribe(() => {
        const now = new Date();
        if (this.lastActivity?.getDate() === undefined) {
          return;
        }

        if (
          now.getTime() - this.lastActivity?.getTime() >
          this.timeout * 1000
        ) {
          localStorage.setItem('idleMessage', this.idleError);
          this.idleSubject.next(true);
          return;
        }

        if (
          now.getTime() - this.lastActivity.getTime() <=
          this.idleCheckInterval * 2001
        ) {
          this.validateTokenService.fetchValidateToken();
        }
      });
  }

  resetTimer() {
    this.lastActivity = new Date();
    this.idleStatus = false;
    this.idleSubject.next(false);
  }

  stopWatching() {
    if (this.idleSubscription) {
      this.idleSubscription.unsubscribe();
    }
  }

  /**
   * Read any stored message that may persist in browser storage when an idle session is logged out and remove any
   * stored message from the browser storage.
   *
   * @returns(string | null) - Returns either the stored message or null if no message exists.
   *
   * @usageNotes
   * The method is intended to be called once from the LoginComponent, which helps guarantee that the idle message is
   * only shown once.
   */
  public takeAndForget(): string | null {
    const message = localStorage.getItem('idleMessage');
    localStorage.removeItem('idleMessage');
    return message;
  }
}
