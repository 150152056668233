import {
  Component,
  HostListener,
  OnDestroy,
  OnInit,
  inject,
} from '@angular/core';
import { RouterModule, RouterOutlet } from '@angular/router';
import { Subscription, distinctUntilChanged, switchMap } from 'rxjs';

import { DevOverlayComponent } from './dev-overlay/dev-overlay.component';
import { FooterComponent } from './footer/footer.component';
import { HeaderComponent } from './header/header.component';
import { PaperlessBillingModalComponent } from './paperless-billing-modal/paperless-billing-modal.component';
import { AuthService } from './services/auth/auth.service';
import {
  ClientSettingsService,
  clientSettingsResponse,
} from './services/client-settings/client-settings.service';
import { IdleService } from './services/idle/idle.service';
import { ValidateTokenService } from './services/validate-token/validate-token.service';

@Component({
  selector: 'app-root',
  standalone: true,
  templateUrl: './app.component.html',
  styleUrl: './app.component.scss',
  imports: [
    RouterModule,
    RouterOutlet,
    DevOverlayComponent,
    FooterComponent,
    HeaderComponent,
    PaperlessBillingModalComponent,
  ],
})
export class AppComponent implements OnInit, OnDestroy {
  authService = inject(AuthService);
  clientSettingsService = inject(ClientSettingsService);
  idleService = inject(IdleService);
  validateTokenService = inject(ValidateTokenService);

  // Site Data
  title = 'My-Doc-Bill';

  private idleSubscription?: Subscription;

  // Settings
  private settingsSubscription: Subscription | undefined;

  @HostListener('window:mousemove')
  @HostListener('window:keydown')
  @HostListener('window:click')
  @HostListener('window:scroll')
  onUserInteraction() {
    this.idleService.resetTimer();
  }

  ngOnInit(): void {
    let clientColorSet = false;

    this.idleSubscription = this.idleService.idleState
      .pipe(
        distinctUntilChanged(),
        switchMap(async isIdle => {
          if (this.authService.isAuthenticated()) {
            if (isIdle) {
              await this.authService.logout();
            }

            return;
          }

          this.idleService.resetTimer();
        })
      )
      .subscribe();

    this.idleService.startWatching();

    this.settingsSubscription = this.clientSettingsService.response$.subscribe({
      next: (res: clientSettingsResponse) => {
        if (res) {
          if (
            this.authService.isAuthenticated() ||
            this.authService.isPartiallyAuthenticated()
          ) {
            // Fetch data from service
            this.validateTokenService.fetchValidateToken();
          }

          if (window.CSS && window.CSS.registerProperty) {
            if (res.primaryColor && !clientColorSet) {
              window.CSS.registerProperty({
                name: '--client-color',
                syntax: '*',
                inherits: false,
                initialValue: res.primaryColor.toString(),
              });
              clientColorSet = true;
            }
          }
        }
      },
    });

    // Fetch data from service
    this.clientSettingsService.fetchClientSettings();
  }

  ngOnDestroy() {
    if (this.idleSubscription) {
      this.idleService.stopWatching();
      this.idleSubscription.unsubscribe();
    }

    if (this.settingsSubscription) {
      this.settingsSubscription.unsubscribe();
    }
  }
}
