import { CommonModule } from '@angular/common';
import { Component, inject, OnInit } from '@angular/core';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';

import { FeedbackService } from '../../services/feedback/feedback.service';

@Component({
  selector: 'app-payment-survey',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './payment-survey.component.html',
  styleUrl: './payment-survey.component.scss',
})
export class PaymentSurveyComponent implements OnInit {
  surveyUrl: SafeUrl | undefined = '';

  feedbackService = inject(FeedbackService);
  sanitizer = inject(DomSanitizer);

  ngOnInit() {
    this.feedbackService.fetchPaymentFeedbackUrl();
    this.feedbackService.paymentFeedbackResponse$.subscribe({
      next: () => {
        this.surveyUrl = this.sanitizer.bypassSecurityTrustResourceUrl(
          this.feedbackService.getPaymentFeedbackSurveyInfo().surveyUrl
        );
      },
    });
  }
}
