import { provideHttpClient, withInterceptors } from '@angular/common/http';
import { ApplicationConfig } from '@angular/core';
import {
  provideRouter,
  withEnabledBlockingInitialNavigation,
  withInMemoryScrolling,
} from '@angular/router';
import { RECAPTCHA_SETTINGS, RecaptchaSettings } from 'ng-recaptcha';

import { environment } from '../environments/environment';

import { routes } from './app.routes';
import { INTERCEPTORS } from './interceptors/interceptors';
import { Logger } from './observability/logger';
import { provideObservability } from './observability/provider';
import { BrowserService } from './services/browser/browser.service';
import {
  provideAppBaseHref,
  VirtualSiteService,
} from './services/virtual-site/virtual-site.service';

const staticProviders = [
  provideRouter(
    routes,
    withEnabledBlockingInitialNavigation(),
    withInMemoryScrolling({
      scrollPositionRestoration: 'enabled',
      anchorScrolling: 'enabled',
    })
  ),
  provideHttpClient(withInterceptors(INTERCEPTORS)),
  {
    provide: RECAPTCHA_SETTINGS,
    useValue: {
      siteKey: environment.recaptcha_site_key,
    } as RecaptchaSettings,
  },
  {
    provide: VirtualSiteService,
    useFactory: VirtualSiteService.factory,
  },
];

async function buildConfiguration(): Promise<ApplicationConfig> {
  const loggerFactory = (name: string) => new Logger(name);
  return {
    providers: [
      ...staticProviders,
      provideObservability(),
      await provideAppBaseHref(
        loggerFactory,
        new BrowserService(loggerFactory, window)
      ),
    ],
  } as ApplicationConfig;
}

export const AppConfig = {
  buildConfiguration: buildConfiguration,
};
