<h1 class="visually-hidden">FAQs</h1>
@defer (
  when this.faqService.complete === true && this.clientSettingsService.complete === true
) {
  @if (this.clientSettingsService.getClientSettings().faqUrl !== '') {
    <h2 class="t-heading">
      {{ this.clientSettingsService.getClientSettings().companyNameDisplay }}
      FAQs
    </h2>

    <a
      target="_blank"
      rel="noopener external"
      class="custom-faq t-body-large"
      [href]="this.clientSettingsService.getClientSettings().faqUrl"
      >Click here for
      {{ this.clientSettingsService.getClientSettings().companyNameDisplay }}
      FAQs</a
    >
  }

  <h2 class="t-heading">Billing FAQs</h2>
  <ol class="faq-list">
    @for (question of questions; track question) {
      <li class="faq-list__item">
        <details name="faq">
          <summary class="faq-list__item__question">
            {{ question.question }}
          </summary>
          <p class="faq-list__item__answer t-body">{{ question.answer }}</p>
        </details>
      </li>
    }
  </ol>
} @placeholder (minimum 500ms) {
  <p>Loading...</p>
}
