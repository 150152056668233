<div class="footer">
  <a
    [href]="this.privacyPolicyUrl"
    class="footer__link t-body"
    target="_blank"
    data-testid="footer-privacy-policy-link">
    Privacy Policy
    <span class="visually-hidden">(opens in a new window)</span>
  </a>
  <span aria-hidden="true"> - </span>
  <a
    [href]="this.termsOfUseUrl"
    class="footer__link t-body"
    target="_blank"
    data-testid="footer-terms-of-use-link">
    Terms of Use
    <span class="visually-hidden">(opens in a new window)</span>
  </a>
  <span aria-hidden="true"> - </span>
  <a
    [routerLink]="['/support']"
    class="footer__link t-body"
    data-testid="footer-faq-link">
    FAQs
  </a>
</div>
