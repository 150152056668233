import {
  HttpErrorResponse,
  HttpInterceptorFn,
  HttpStatusCode,
} from '@angular/common/http';
import { inject } from '@angular/core';
import { catchError, throwError } from 'rxjs';

import { ILogger } from '../../observability/logger';
import { LOGGER_FACTORY } from '../../observability/provider';

export const errorInterceptor: HttpInterceptorFn = (request, next) => {
  const logger = inject(LOGGER_FACTORY)('ErrorHttpInterceptor');

  return next(request).pipe(
    catchError((error: unknown) => {
      if (error instanceof HttpErrorResponse) {
        return throwError(() => handleHttpErrorResponse(error, logger));
      }

      logger.error('Unknown HTTP Error', undefined, error);
      return throwError(() => error);
    })
  );
};

function handleHttpErrorResponse(
  httpErrorResponse: HttpErrorResponse,
  logger: ILogger
): HttpClientError | HttpServerError {
  let error: HttpClientError | HttpServerError;

  let message = '';
  let context: object | undefined = undefined;

  if (httpErrorResponse.error instanceof ProgressEvent) {
    error = HttpClientError.fromHttpErrorResponse(httpErrorResponse);

    message = httpErrorResponse.message;
  } else {
    error = HttpServerError.fromHttpErrorResponse(httpErrorResponse);

    message = httpErrorResponse.message;

    context = {
      status: httpErrorResponse.status,
      statusText: HttpStatusCode[httpErrorResponse.status],
    };
  }

  logger.error(message, context, error);

  return error;
}

export class HttpClientError extends Error {
  private constructor(messaage: string) {
    super(messaage);
    this.name = 'HttpClientError';
  }

  public static fromHttpErrorResponse(
    httpErrorResponse: HttpErrorResponse
  ): HttpClientError {
    return new HttpClientError(httpErrorResponse.message);
  }
}

export class HttpServerError extends Error {
  private constructor(
    messaage: string,
    status: number,
    statusDescription: string
  ) {
    super(`${status} ${statusDescription} - ${messaage}`);
    this.name = 'HttpServerError';
  }

  public static fromHttpErrorResponse(
    httpErrorResponse: HttpErrorResponse
  ): HttpServerError {
    return new HttpServerError(
      httpErrorResponse.message,
      httpErrorResponse.status,
      httpErrorResponse.statusText
    );
  }
}
