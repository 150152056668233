import { A11yModule, LiveAnnouncer } from '@angular/cdk/a11y';
import { CommonModule } from '@angular/common';
import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ElementRef,
  OnDestroy,
  OnInit,
  ViewChild,
  inject,
} from '@angular/core';
import {
  ReactiveFormsModule,
  Validators,
  FormBuilder,
  FormGroup,
} from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { RecaptchaModule, RecaptchaFormsModule } from 'ng-recaptcha';
import { Subscription } from 'rxjs';

import { verifyFeatureToggle } from '../helpers/helpers';
import {
  accountLookupRequest,
  accountLookupResponse,
  AccountLookupService,
} from '../services/account-lookup/account-lookup.service';
import {
  authenticationByCodeRequest,
  authenticationByCodeResponse,
  AuthenticationByCodeService,
} from '../services/authentication-by-code/authentication-by-code.service';
import { BrowserService } from '../services/browser/browser.service';
import {
  clientSettingsResponse,
  ClientSettingsService,
  featureToggles,
} from '../services/client-settings/client-settings.service';
import { VirtualSiteService } from '../services/virtual-site/virtual-site.service';
import { SvgComponent } from '../svg/svg.component';

@Component({
  selector: 'app-account-lookup',
  standalone: true,
  imports: [
    SvgComponent,
    ReactiveFormsModule,
    CommonModule,
    A11yModule,
    RecaptchaFormsModule,
    RecaptchaModule,
  ],
  templateUrl: './account-lookup.component.html',
  styleUrl: './account-lookup.component.scss',
})
export class AccountLookupComponent
  implements OnInit, AfterViewInit, OnDestroy
{
  @ViewChild('accountLookupModal')
  accountLookupModal!: ElementRef<HTMLDialogElement>;
  accountLookupForm!: FormGroup;
  accountVerificationForm!: FormGroup;
  changeDetectorRef = inject(ChangeDetectorRef);
  accountLookupService = inject(AccountLookupService);
  authenticationByCode = inject(AuthenticationByCodeService);
  browserService = inject(BrowserService);
  clientSettingsService = inject(ClientSettingsService);
  virtualSiteService = inject(VirtualSiteService);
  lookupIsSubmitted = false;
  accountLookupSubscription!: Subscription;
  clientSettingsSubscription!: Subscription;
  accountAuthByCodeSubscription!: Subscription;
  accountCodeGen!: Subscription;
  hasCodeGenError: boolean = false;
  allowReCaptcha: boolean = true;
  featureToggles: featureToggles[] | undefined;

  hasAuthError: boolean = false;
  error = 'Error, please try again';
  defaultContactMethod = 'Email';
  allowEmail!: boolean;
  allowTextMessage!: boolean;

  ngOnInit() {
    this.clientSettingsSubscription =
      this.clientSettingsService.response$.subscribe(
        (res: clientSettingsResponse) => {
          if (
            !this.clientSettingsService.getClientSettings().enableAccountLookup
          ) {
            this.route.navigate(['/login']);
          } else {
            // Set defaultContactMethod
            this.allowEmail =
              this.clientSettingsService.getClientSettings().offerEmail;
            this.allowTextMessage =
              this.clientSettingsService.getClientSettings().offerTextMessage;

            if (!this.allowEmail && this.allowTextMessage) {
              this.defaultContactMethod = 'Text';
            }
            this.accountLookupForm = this.fb.group({
              contactType: [this.defaultContactMethod, [Validators.required]],
              emailAddress: [''],
              textNumber: [''],
              dobMonth: [
                '',
                [Validators.required, Validators.pattern('^[0-9]{1,2}$')],
              ],
              dobDay: [
                '',
                [Validators.required, Validators.pattern('^[0-9]{1,2}$')],
              ],
              dobYear: [
                '',
                [Validators.required, Validators.pattern('^[0-9]{4}$')],
              ],
              recaptchaReactive: [null],
            });

            this.accountVerificationForm = this.fb.group({
              verificationCode: ['', [Validators.required]],
            });

            this.onContactTypeChange();
          }

          if (res) {
            this.featureToggles =
              this.clientSettingsService.getClientSettings().featureToggles;
            this.allowReCaptcha = verifyFeatureToggle(
              'AllowRecaptchaResponse',
              this.featureToggles
            );
            if (this.allowReCaptcha) {
              this.accountLookupForm.controls[
                'recaptchaReactive'
              ].setValidators([Validators.required]);
            } else {
              this.accountLookupForm.controls[
                'recaptchaReactive'
              ].clearValidators();
            }
            this.accountLookupForm.controls[
              'recaptchaReactive'
            ].updateValueAndValidity();
          }
        }
      );

    this.accountLookupSubscription =
      this.accountLookupService.response$.subscribe({
        next: (res: accountLookupResponse | null) => {
          this.hasCodeGenError = false;
          this.error = 'Error, please try again';

          if (res && res.isSuccess) {
            this.lookupIsSubmitted = true;
          } else if (res && !res.isSuccess) {
            if (res.error !== '') {
              this.error = res.error;
            }
            this.hasCodeGenError = true;
            this.liveAnnouncer.announce(this.error);
          }
        },
      });

    this.accountAuthByCodeSubscription =
      this.authenticationByCode.response$.subscribe({
        next: (res: authenticationByCodeResponse) => {
          this.hasAuthError = false;
          this.hasCodeGenError = false;
          this.error = 'Error, please try again';
          if (res && res.isSuccess && res.token) {
            this.clientSettingsService.fetchClientSettings();
            this.clientSettingsService.response$.subscribe({
              next: response => {
                if (!this.virtualSiteService.isSameSite(response.siteUrl)) {
                  this.browserService.reload('Token Created from Code');
                  return;
                }

                this.route.navigate(['/app']);
              },
            });
          } else if (res && !res.isSuccess) {
            this.error = 'Error, please try again';
            this.hasAuthError = true;
            this.liveAnnouncer.announce(this.error);
          }
        },
      });
  }

  private onContactTypeChange() {
    this.accountLookupForm.get('contactType')!.valueChanges.subscribe(value => {
      this.setConditionalValidators(value);
    });

    // Set initial state
    this.setConditionalValidators(
      this.accountLookupForm.get('contactType')!.value
    );
  }

  private setConditionalValidators(contactType: string) {
    const emailControl = this.accountLookupForm.get('emailAddress');
    const phoneNumberControl = this.accountLookupForm.get('textNumber');

    if (contactType === 'Email') {
      emailControl!.setValidators([Validators.required, Validators.email]);
      phoneNumberControl!.clearValidators();
    } else {
      phoneNumberControl!.setValidators([
        Validators.required,
        Validators.pattern('^[0-9]{10}$'),
      ]);
      emailControl!.clearValidators();
    }

    emailControl!.updateValueAndValidity();
    phoneNumberControl!.updateValueAndValidity();
    this.changeDetectorRef.detectChanges();
  }

  constructor(
    private route: Router,
    private activeRoute: ActivatedRoute,
    private fb: FormBuilder,
    private liveAnnouncer: LiveAnnouncer
  ) {}

  ngAfterViewInit() {
    this.accountLookupModal.nativeElement.show();
  }

  async closeModal() {
    this.accountLookupForm.reset();
    this.accountLookupService.resetAccountLookup();
    this.hasAuthError = false;
    this.hasCodeGenError = false;
    this.lookupIsSubmitted = false;
    this.accountLookupModal.nativeElement.close();
    await this.handleClose();
  }

  async handleClose() {
    await this.route.navigate(['../'], { relativeTo: this.activeRoute });
  }

  onAccountLookupSubmit() {
    this.hasCodeGenError = false;
    this.hasAuthError = false;
    this.error = 'Error, please try again';

    const formData: accountLookupRequest = {
      communicationMethod:
        this.accountLookupForm.get('contactType')?.value === 'Email'
          ? 'EML'
          : 'SMS',
      destination:
        this.accountLookupForm.get('contactType')?.value === 'Email'
          ? this.accountLookupForm.get('emailAddress')?.value
          : this.accountLookupForm.get('textNumber')?.value,
      dobMonth: this.accountLookupForm.get('dobMonth')?.value,
      dobDay: this.accountLookupForm.get('dobDay')?.value,
      dobYear: this.accountLookupForm.get('dobYear')?.value,
      siteUrl: this.virtualSiteService.site.url,
    };

    this.accountLookupService.fetchAccountLookup(formData);
  }

  onAccountLookupResubmit() {
    this.hasCodeGenError = false;
    this.hasAuthError = false;
    this.error = 'Error, please try again';

    const formData: accountLookupRequest = {
      communicationMethod:
        this.accountLookupForm.get('contactType')?.value === 'Email'
          ? 'EML'
          : 'SMS',
      destination:
        this.accountLookupForm.get('contactType')?.value === 'Email'
          ? this.accountLookupForm.get('emailAddress')?.value
          : this.accountLookupForm.get('textNumber')?.value,
      dobMonth: this.accountLookupForm.get('dobMonth')?.value,
      dobDay: this.accountLookupForm.get('dobDay')?.value,
      dobYear: this.accountLookupForm.get('dobYear')?.value,
      siteUrl: this.virtualSiteService.site.url,
    };

    this.accountLookupService.fetchAccountLookup(formData);
  }

  onAccountVerificationSubmit() {
    this.hasCodeGenError = false;
    this.hasAuthError = false;
    this.error = 'Error, please try again';

    const formData: authenticationByCodeRequest = {
      verificationCode:
        this.accountVerificationForm.get('verificationCode')?.value,
      destination:
        this.accountLookupForm.get('contactType')?.value === 'Email'
          ? this.accountLookupForm.get('emailAddress')?.value
          : this.accountLookupForm.get('textNumber')?.value,
      dobMonth: this.accountLookupForm.get('dobMonth')?.value,
      dobDay: this.accountLookupForm.get('dobDay')?.value,
      dobYear: this.accountLookupForm.get('dobYear')?.value,
      siteUrl: this.virtualSiteService.site.url,
    };

    this.authenticationByCode.fetchAuthenticationByCode(formData);
  }

  ngOnDestroy() {
    this.hasAuthError = false;
    this.hasCodeGenError = false;
    this.clientSettingsSubscription.unsubscribe();
    this.accountAuthByCodeSubscription.unsubscribe();
    this.accountLookupSubscription.unsubscribe();
  }
}
