import { CommonModule } from '@angular/common';
import { Component, OnInit, inject } from '@angular/core';
import { RouterModule, Router } from '@angular/router';

import { HeroImageComponent } from '../hero-image/hero-image.component';
import { NavigationComponent } from '../navigation/navigation.component';
import {
  ClientSettingsService,
  clientSettingsResponse,
} from '../services/client-settings/client-settings.service';

@Component({
  selector: 'app-header',
  standalone: true,
  templateUrl: './header.component.html',
  styleUrl: './header.component.scss',
  imports: [
    CommonModule,
    RouterModule,
    HeroImageComponent,
    NavigationComponent,
  ],
})
export class HeaderComponent implements OnInit {
  router = inject(Router);
  clientSettingsService = inject(ClientSettingsService);

  logoGraphic: string =
    this.clientSettingsService.getClientSettings().logoGraphic;
  siteUrl: string = this.clientSettingsService.getClientSettings().siteUrl;
  companyNameDisplay: string =
    this.clientSettingsService.getClientSettings().companyNameDisplay;

  constructor() {}

  ngOnInit(): void {
    this.clientSettingsService.response$.subscribe(
      (res: clientSettingsResponse | null) => {
        if (res) {
          if (res.logoGraphic === '') {
            this.logoGraphic = '/assets/images/logo_transparent.png';
          } else {
            this.logoGraphic = 'data:image/png;base64,' + res.logoGraphic;
          }
          res.companyNameDisplay &&
            (this.companyNameDisplay = res.companyNameDisplay);

          res.siteUrl && (this.siteUrl = res.siteUrl);
        }
      }
    );
  }

  isLoginPage(): boolean {
    return this.router.url.includes('/login');
  }
}
