import { CommonModule } from '@angular/common';
import { Component, inject, OnInit } from '@angular/core';
import { FormBuilder, ReactiveFormsModule, Validators } from '@angular/forms';

import { verifyFeatureToggle } from '../../../../helpers/helpers';
import { ClientSettingsService } from '../../../../services/client-settings/client-settings.service';
import { PaymentLibraryService } from '../../../../services/payment-library/payment-library.service';
import { PaymentPlanServiceService } from '../../services/payment-plan-service.service';

export interface ICreditCardOptions {
  includeNotes: boolean | undefined;
  includeSwipe: boolean | undefined;
  includeBillingAddress: boolean | undefined;
}

export interface IECheckOptions {
  includeNotes: boolean | undefined;
  includeBillingAddress: boolean | undefined;
}

export interface IPaymentModuleApiV3 {
  InitCreditCardForm(
    mountElementId: string,
    options: ICreditCardOptions | undefined
  ): void;

  InitECheckForm(
    mountElementId: string,
    options: IECheckOptions | undefined
  ): void;

  //IPaymentModuleForm -> any
  InitDWForm(mountElementId: string): void;

  SubmitCheckoutResponse(checkoutResponse: any): void; // eslint-disable-line @typescript-eslint/no-explicit-any
}

@Component({
  selector: 'app-payment-plan-information',
  standalone: true,
  imports: [ReactiveFormsModule, CommonModule],
  templateUrl: './payment-plan-information.component.html',
  styleUrl: './payment-plan-information.component.scss',
})
export class PaymentPlanInformationComponent implements OnInit {
  paymentLibraryService = inject(PaymentLibraryService);
  paymentPlanService = inject(PaymentPlanServiceService);
  clientSettingsService = inject(ClientSettingsService);

  paymentLibraryCode: string | undefined = undefined;

  paymentModuleApi!: IPaymentModuleApiV3;

  constructor(private fb: FormBuilder) {}

  paymentTypeForm = this.fb.group({
    paymentType: ['', [Validators.required]],
    agreeToTerms: [false, [Validators.required]],
  });

  ngOnInit(): void {
    this.paymentLibraryService.response$.subscribe({
      next: (res: string) => {
        if (res !== '') {
          this.paymentLibraryCode = res;
          if (
            this.paymentLibraryCode !== '' ||
            this.paymentLibraryCode !== undefined
          ) {
            this.paymentModuleApi = (window as any).PaymentModule; // eslint-disable-line @typescript-eslint/no-explicit-any
            this.onContactTypeChange();
          }
        }
      },
    });
  }

  /* istanbul ignore next we should test this but its killing dev time right now, sorry future me */
  onContactTypeChange() {
    let ccForm: any; // eslint-disable-line @typescript-eslint/no-explicit-any
    let creditCardFormHandle: any; // eslint-disable-line @typescript-eslint/no-explicit-any
    let ccFormInit = false;

    let ecForm: any; // eslint-disable-line @typescript-eslint/no-explicit-any
    let eCheckFormHandle: any; // eslint-disable-line @typescript-eslint/no-explicit-any
    let ecFormInit = false; // eslint-disable-line @typescript-eslint/no-explicit-any

    const creditCardContainer = 'payment-module-credit-card-container';
    const eCheckContainer = 'payment-module-electronic-check-container';

    this.paymentTypeForm.get('paymentType')!.valueChanges.subscribe(value => {
      const featureToggles =
        this.clientSettingsService.getClientSettings().featureToggles;
      const hasEnableCreditCardFormWithBillingAddress = verifyFeatureToggle(
        'EnableCreditCardFormWithBillingAddress',
        featureToggles
      );
      if (value === 'cc') {
        if (ecFormInit) {
          eCheckFormHandle.Disable();
          eCheckFormHandle.Dispose();
        }
        ccForm = this.paymentModuleApi.InitCreditCardForm(creditCardContainer, {
          includeSwipe: false,
          includeNotes: false,
          includeBillingAddress: hasEnableCreditCardFormWithBillingAddress,
        });
        ccFormInit = true;
        creditCardFormHandle = this.paymentLibraryService.configureForm(
          'payment-plan',
          ccForm,
          this.paymentPlanService.paymentSelections
        );
        creditCardFormHandle.Disable();
        this.paymentTypeForm.get('agreeToTerms')?.setValue(false);
      } else if (value === 'ec') {
        if (ccFormInit) {
          creditCardFormHandle.Disable();
          creditCardFormHandle.Dispose();
        }
        ecForm = this.paymentModuleApi.InitECheckForm(eCheckContainer, {
          includeNotes: false,
          includeBillingAddress: hasEnableCreditCardFormWithBillingAddress,
        });
        ecFormInit = true;
        eCheckFormHandle = this.paymentLibraryService.configureForm(
          'payment-plan',
          ecForm,
          this.paymentPlanService.paymentSelections
        );
        eCheckFormHandle.Disable();
        this.paymentTypeForm.get('agreeToTerms')?.setValue(false);
      }
    });

    this.paymentTypeForm.get('agreeToTerms')!.valueChanges.subscribe(value => {
      const isChecked = value;
      const selectedPayment = this.paymentTypeForm.get('paymentType')?.value;

      if (isChecked) {
        if (selectedPayment === 'cc') {
          creditCardFormHandle.Enable();
        } else if (selectedPayment === 'ec') {
          eCheckFormHandle.Enable();
        }
      } else {
        if (selectedPayment === 'cc') {
          creditCardFormHandle.Disable();
        } else if (selectedPayment === 'ec') {
          eCheckFormHandle.Disable();
        }
      }
    });
  }
}
