import { HttpClient, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

import { environment } from '../../../environments/environment';

export interface authenticationByCodeRequest {
  verificationCode: string;
  destination: string;
  dobMonth: string;
  dobDay: string;
  dobYear: string;
  siteUrl: string;
}

export interface authenticationByCodeResponse {
  isSuccess: boolean;
  error: string;
  token: string;
  needsBillingGroup: boolean;
}

@Injectable({
  providedIn: 'root',
})
export class AuthenticationByCodeService {
  endPointBase: string = '/AuthenticationByCode';

  response!: authenticationByCodeResponse;
  loading: boolean = false;
  complete: boolean = false;

  private responseSubject = new BehaviorSubject<authenticationByCodeResponse>(
    this.response
  );
  response$ = this.responseSubject.asObservable();

  constructor(private http: HttpClient) {}

  fetchAuthenticationByCode(formData: unknown) {
    this.http
      .post<authenticationByCodeResponse>(
        environment.apiUrl + this.endPointBase,
        formData,
        {
          observe: 'response',
        }
      )
      .subscribe({
        next: (res: HttpResponse<authenticationByCodeResponse>) => {
          if (res.status === 200 && res.body) {
            this.response = res.body;
            localStorage.setItem('token', this.response.token);
            this.responseSubject.next(res.body);
            this.complete = true;
          }
        },
      });
  }

  getAuthenticationByCode() {
    return this.response; // Return data stored in the service
  }
}
