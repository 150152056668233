import { CommonModule } from '@angular/common';
import { Component, OnInit, inject, OnDestroy } from '@angular/core';
import { RouterLink, Router } from '@angular/router';
import { Subscription } from 'rxjs';

import { ChargeSearchComponent } from '../charge-search/charge-search.component';
import { ChargesComponent } from '../charges/charges.component';
import { BannerComponent } from '../communication-banner/banner.component';
import { verifyFeatureToggle } from '../helpers/helpers';
import { PendingTransactionsComponent } from '../pending-transactions/pending-transactions.component';
import { balanceResponse } from '../services/balance/balance-response';
import { BalanceService } from '../services/balance/balance.service';
import {
  clientSettingsResponse,
  ClientSettingsService,
} from '../services/client-settings/client-settings.service';
import { PaperlessBillingService } from '../services/paperless-billing/paperless-billing.service';
import { PendingTransactionsService } from '../services/pending-transactions/pending-transactions.service';
import {
  StatementService,
  statementRequest,
} from '../services/statement/statement.service';
import { ValidateTokenService } from '../services/validate-token/validate-token.service';
import { SvgComponent } from '../svg/svg.component';

@Component({
  selector: 'app-home',
  standalone: true,
  templateUrl: './home.component.html',
  styleUrl: './home.component.scss',
  imports: [
    BannerComponent,
    RouterLink,
    CommonModule,
    ChargesComponent,
    SvgComponent,
    ChargeSearchComponent,
    PendingTransactionsComponent,
  ],
  providers: [StatementService],
})
export class HomeComponent implements OnInit, OnDestroy {
  router = inject(Router);
  balanceService = inject(BalanceService);
  paperlessBillingService = inject(PaperlessBillingService);
  clientSettingsService = inject(ClientSettingsService);
  validateTokenService = inject(ValidateTokenService);
  statementService = inject(StatementService);
  pendingTransactionsService = inject(PendingTransactionsService);
  pendingChargesDetailsOpen: boolean = true;
  searchChargesDetailsOpen: boolean = true;
  hasEnablePendingPaymentWarnings!: boolean;

  openCharges!: balanceResponse['openCharges'];
  openChargesByPatients!: balanceResponse['openChargesByPatients'];
  private balanceSubscription: Subscription | undefined;
  private clientSettingsSubscription: Subscription | undefined;

  constructor() {}

  ngOnInit() {
    this.balanceService.fetchBalance();
    this.pendingTransactionsService.fetchPendingTransactions();
    this.balanceSubscription = this.balanceService.response$.subscribe({
      next: (res: balanceResponse) => {
        if (res) {
          this.openCharges = res.openCharges;
          this.openChargesByPatients = res.openChargesByPatients;
        }
      },
    });

    this.clientSettingsService.fetchClientSettings();
    this.clientSettingsSubscription =
      this.clientSettingsService.response$.subscribe({
        next: (res: clientSettingsResponse) => {
          if (res) {
            this.hasEnablePendingPaymentWarnings = verifyFeatureToggle(
              'EnablePendingPaymentWarnings',
              this.clientSettingsService.getClientSettings().featureToggles
            );
          }
        },
      });
  }

  pendingDetailsClickHandler() {
    this.pendingChargesDetailsOpen = !this.pendingChargesDetailsOpen;
  }

  searchDetailsClickHandler() {
    this.searchChargesDetailsOpen = !this.searchChargesDetailsOpen;
  }

  generateStatement(request: statementRequest) {
    this.statementService.fetchStatement(request);
  }

  ngOnDestroy() {
    this.clientSettingsSubscription?.unsubscribe();
    this.balanceSubscription?.unsubscribe();
  }
}
