import { Component, OnInit, inject } from '@angular/core';

import {
  ClientSettingsService,
  clientSettingsResponse,
} from '../services/client-settings/client-settings.service';

@Component({
  selector: 'app-hero-image',
  standalone: true,
  templateUrl: './hero-image.component.html',
  styleUrl: './hero-image.component.scss',
})
export class HeroImageComponent implements OnInit {
  constructor() {}

  clientSettingsService = inject(ClientSettingsService);

  bannerGraphic: string =
    this.clientSettingsService.getClientSettings().bannerGraphic;

  ngOnInit(): void {
    this.clientSettingsService.response$.subscribe(
      (res: clientSettingsResponse) => {
        if (res) {
          if (res.bannerGraphic === '') {
            this.bannerGraphic = '/assets/images/green_header.jpg';
          } else {
            this.bannerGraphic = 'data:image/png;base64,' + res.bannerGraphic;
          }
        }
      }
    );
  }
}
