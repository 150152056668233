<dialog #uspsDialog class="usps__modal modal" data-testid="usps-dialog">
  <button
    class="usps__modal__close-button button-inline"
    (click)="cancelUspsModal()">
    <app-svg
      icon="#close"
      fill="var(--icon-fill)"
      [inlineSize]="24"
      [blockSize]="24" />
    <span class="visually-hidden">Close</span>
  </button>
  <div class="usps-modal__header">
    <h1 class="usps-modal__header__heading">Address Verification</h1>
  </div>
  <fieldset class="usps-modal__radio-group">
    <legend class="usps-modal__header__body t-body-large">
      Select the most accurate address
    </legend>
    @defer (when this.uspsComplete === true) {
      <label
        class="usps-modal__radio-address-group__label usps-modal__radio-address-group__label--divider t-body">
        <input
          class="usps-modal__radio-address-group__label__input"
          type="radio"
          name="address_verification"
          checked="checked"
          (click)="radioSelectEnteredAddress()"
          data-testid="address-verification-entered-address" />
        <span class="usps-modal__radio-address-group__label__heading t-bold"
          >Entered Address</span
        >
        <span class="usps-modal__radio-address-group__label__address-container">
          <span>{{ this.inputAddress1 }}</span>
          <span>{{ this.inputAddress2 }}</span>
          <span>{{ this.inputZip }}</span>
          <span>{{ this.inputCity }},</span>
          <span>{{ this.inputState }}</span>
        </span>
      </label>
      <label class="usps-modal__radio-address-group__label t-body">
        <input
          class="usps-modal__radio-address-group__input"
          type="radio"
          name="address_verification"
          (click)="radioSelectUspsAddress()"
          data-testid="address-verification-entered-usps"
          [disabled]="!this.uspsAddress.isSuccess" />
        <span class="usps-modal__radio-address-group__label__heading t-bold"
          >Suggested Address</span
        >
        <span class="usps-modal__radio-address-group__label__address-container">
          <span>{{ this.uspsAddress.address1 }}</span>
          <span>{{ this.uspsAddress.address2 }}</span>
          <span>{{ this.uspsAddress.zip }}</span>
          <span>
            @if (this.uspsAddress.isSuccess === true) {
              {{ this.uspsAddress.city }},
            } @else {
              {{ this.uspsAddress.city }}
            }
          </span>
          <span>{{ this.uspsAddress.state }}</span>
        </span>
      </label>
    } @placeholder {
      <p>Loading</p>
    }
  </fieldset>
  <div class="usps-modal__address__buttons">
    <button
      class="usps-modal__address__buttons__cancel button-secondary"
      data-testid="usps-modal__address__buttons__cancel"
      (click)="cancelUspsModal()">
      Cancel
    </button>
    <button
      class="usps-modal__address__buttons__use button"
      data-testid="usps-modal__address__buttons__use"
      (click)="useVerifiedAddress()">
      Verify Address
    </button>
  </div>
</dialog>
