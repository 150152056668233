import { Routes } from '@angular/router';

import { AccountLookupComponent } from './account-lookup/account-lookup.component';
import { isAuthenticated } from './core/guards/is-authenticated/is-authenticated.guard';
import { isPartiallyAuthenticated } from './core/guards/is-partially-authenticated/is-partially-authenticated.guard';
import { isUnauthenticated } from './core/guards/is-unauthenticated/is-unauthenticated.guard';
import { DashboardComponent } from './dashboard/dashboard.component';
import { DemographicsComponent } from './demographics/demographics/demographics.component';
import { FeedbackComponent } from './feedback/feedback.component';
import { HomeComponent } from './home/home.component';
import { LoginComponent } from './login/login.component';
import { PaymentComponent } from './payments/payment/payment.component';
import { PaymentPlanComponent } from './payments/payment-plan/payment-plan.component';
import { PaymentSurveyComponent } from './payments/payment-survey/payment-survey.component';
import { SupportComponent } from './support/support.component';

export const routes: Routes = [
  {
    path: '',
    redirectTo: 'app/home',
    pathMatch: 'full',
  },
  {
    path: 'login',
    component: LoginComponent,
    canActivate: [isUnauthenticated],
    children: [
      {
        path: 'account-lookup',
        component: AccountLookupComponent,
      },
    ],
  },
  {
    path: 'app/login',
    redirectTo: 'login',
    pathMatch: 'prefix',
  },
  {
    path: 'app',
    component: DashboardComponent,
    canActivate: [isPartiallyAuthenticated],
    canActivateChild: [isAuthenticated],
    children: [
      {
        path: 'home',
        component: HomeComponent,
      },
      {
        path: 'bill-pay',
        component: PaymentComponent,
      },
      {
        path: 'demographics',
        component: DemographicsComponent,
      },
      {
        path: 'payment-plan',
        component: PaymentPlanComponent,
      },
      {
        path: 'surveyexp',
        component: PaymentSurveyComponent,
      },
    ],
  },
  {
    path: 'support',
    component: SupportComponent,
  },
  {
    path: 'feedback',
    component: FeedbackComponent,
  },
  { path: '**', redirectTo: '/app/home', pathMatch: 'full' },
];
