import { CommonModule } from '@angular/common';
import { Component, Inject, inject } from '@angular/core';

import { formatTime } from '../helpers/helpers';
import { ILogger } from '../observability/logger';
import { LOGGER_FACTORY, LoggerFactory } from '../observability/provider';
import { AuthService } from '../services/auth/auth.service';
import { ValidateTokenService } from '../services/validate-token/validate-token.service';

@Component({
  selector: 'app-dev-overlay',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './dev-overlay.component.html',
  styleUrl: './dev-overlay.component.scss',
})
export class DevOverlayComponent {
  private readonly logger: ILogger;

  validateService = inject(ValidateTokenService);
  validateRunning = false;
  validateRunningDate!: Date | string;
  intervalId: ReturnType<typeof setInterval> | undefined;

  constructor(
    public authService: AuthService,
    @Inject(LOGGER_FACTORY) loggerFactory: LoggerFactory
  ) {
    this.logger = loggerFactory('DevOverlayComponent');
  }

  //Submit Button to check Form Validity and assign recieved JWT Token
  async onLogoutClick() {
    await this.authService.logout();
  }

  onValidateClick() {
    this.validateRunningDate = new Date();

    this.validateRunningDate = formatTime(this.validateRunningDate);

    const reValidate = () => {
      this.validateService.fetchValidateToken();
      this.logger.debug('Validated token via dev panel');
    };

    // Stop running the code after 1 hour (60 minutes)
    setTimeout(
      () => {
        clearInterval(this.intervalId);
        this.logger.debug(
          'Cleared by timeout: Auto-validate token via dev panel'
        );
      },
      60 * 60 * 1000
    ); // Stop after 1 hour (3600000 milliseconds)

    if (!this.validateRunning) {
      this.intervalId = setInterval(reValidate, 60 * 1000); // Set interval for every 1 minute (60000 milliseconds)
      this.logger.debug('Started: Auto-validate token via dev panel');
      this.validateRunning = true;
    } else {
      clearInterval(this.intervalId);
      this.logger.debug('Cleared: Auto-validate token via dev panel');
      this.validateRunning = false;
    }
  }
}
