<form [formGroup]="paymentTypeForm" class="payment-information__form">
  <label class="payment-information__form__label form__label">
    <span class="form__label__text">Payment method</span>
    <select
      data-testid="payment-form-method-select"
      name="paymenType"
      formControlName="paymentType"
      required="required"
      aria-required="true"
      class="form__label__select form__label__select--large">
      <option disabled selected value>Choose one...</option>
      @if (this.clientSettingsService.getClientSettings().acceptCreditCards) {
        <option value="cc" data-testid="payment-form-method-cc-option">
          Credit Card
        </option>
      }
      @if (
        this.clientSettingsService.getClientSettings().acceptElectronicChecks
      ) {
        <option value="ec">Electronic Check</option>
      }
    </select>
  </label>
  @if (this.paymentTypeForm.get('paymentType')?.value === 'cc') {
    <label
      class="payment-information__form__label form__label form__label--checkbox t-body">
      <input class="" type="checkbox" formControlName="agreeToTerms" />
      <span class="form__label__text">
        I have read the agreement and agree to the
        <a
          [href]="this.clientSettingsService.getClientSettings().termsOfUseUrl"
          class="t-link"
          target="_blank"
          data-testid="footer-terms-of-use-link">
          Terms of Use
          <span class="visually-hidden">(opens in a new window)</span>
        </a>
      </span>
    </label>
  } @else if (this.paymentTypeForm.get('paymentType')?.value === 'ec') {
    <label
      class="payment-information__form__label form__label form__label--checkbox t-body">
      <input formControlName="agreeToTerms" class="" type="checkbox" />
      <span class="form__label__text">
        I AUTHORIZE TO USE THE INFORMATION ABOVE TO INITIATE AN ELECTRONIC FUND
        TRANSFER FROM MY ACCOUNT OR TO PROCESS THE PAYMENT AS A CHECK
        TRANSACTION OR BANK DRAWN DRAFT FROM MY ACCOUNT FOR THE AMOUNT OF
        {{
          this.paymentPlanService.paymentSelections.additionalFields
            .paymentAmount | currency
        }}. IF MY PAYMENT IS RETURNED DUE TO INSUFFICIENT FUNDS, I AUTHORIZE TO
        MAKE A ONE-TIME ELECTRONIC FUNDS TRANSFER OR TO USE A BANK DRAFT DRAWN
        FROM MY ACCOUNT TO COLLECT A FEE AS ALLOWED BY STATE LAW.
      </span>
    </label>
  }
</form>
<div
  id="payment-module-credit-card-container"
  [class]="
    this.paymentTypeForm.get('paymentType')?.value === 'cc'
      ? ''
      : 'visually-hidden'
  "></div>
<div
  id="payment-module-electronic-check-container"
  [class]="
    this.paymentTypeForm.get('paymentType')?.value === 'ec'
      ? ''
      : 'visually-hidden'
  "></div>
