import { inject } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivateFn,
  GuardResult,
  Router,
  RouterStateSnapshot,
} from '@angular/router';

import { AuthService } from '../../../services/auth/auth.service';

export const isUnauthenticated: CanActivateFn = (
  _next: ActivatedRouteSnapshot,
  _state: RouterStateSnapshot
): GuardResult => {
  const authService = inject(AuthService);
  const router = inject(Router);

  if (authService.isPartiallyAuthenticated()) {
    return router.createUrlTree(['/app']);
  }

  if (authService.isAuthenticated()) {
    return router.createUrlTree(['/app/home']);
  }

  return true;
};
