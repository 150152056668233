import { CommonModule } from '@angular/common';
import { Component, inject, OnDestroy, OnInit } from '@angular/core';
import {
  FormBuilder,
  FormGroup,
  Validators,
  ReactiveFormsModule,
  FormControl,
} from '@angular/forms';
import { RouterLink, Router } from '@angular/router';
import { combineLatest, filter, merge, startWith, Subscription } from 'rxjs';

import {
  formatToDateString,
  toTwoDecimalPlaceNumberRoundUp,
} from '../../../helpers/helpers';
import { balanceResponse } from '../../../services/balance/balance-response';
import { BalanceService } from '../../../services/balance/balance.service';
import { ClientSettingsService } from '../../../services/client-settings/client-settings.service';
import { PaperlessBillingService } from '../../../services/paperless-billing/paperless-billing.service';
import {
  pendingTransactionsResponse,
  PendingTransactionsService,
} from '../../../services/pending-transactions/pending-transactions.service';
import { PaymentPlanServiceService } from '../services/payment-plan-service.service';

import { minDateValidator, maxDateValidator } from './validators/validators';

export interface paymentPlanFormObject {
  paymentPlan: number;
  startDate: string;
  emailAddress: string;
  additionalFields: {
    endDate: string;
    paymentAmount: number;
    numberOfPayments: number;
    guarantorBalance: number;
  };
}

@Component({
  selector: 'app-payment-plan-selection',
  standalone: true,
  imports: [CommonModule, RouterLink, ReactiveFormsModule],
  templateUrl: './payment-plan-selection.component.html',
  styleUrls: [
    './payment-plan-selection.component.scss',
    '../payment-plan.component.scss',
  ],
})
export class PaymentPlanSelectionComponent implements OnInit, OnDestroy {
  router = inject(Router);
  clientSettingsService = inject(ClientSettingsService);
  balanceService = inject(BalanceService);
  pendingTransactionsService = inject(PendingTransactionsService);
  paymentPlanService = inject(PaymentPlanServiceService);
  paperlessBillingService = inject(PaperlessBillingService);

  initSubscription!: Subscription;
  paymentPlanFormSubscription!: Subscription;
  customPlanSubscription!: Subscription | undefined;
  paymentAmountSubscription!: Subscription | undefined;
  startDateSubscription!: Subscription;
  paymentSelectionsSubscription!: Subscription | undefined;

  // Calculated plan info
  guarantorBalance!: number;
  isPredefinedPlanSelected = true;
  numberOfPayments!: number;
  clientMinimumPaymentAmount!: number;
  clientMaximumNumberOfPayments!: number;
  minimumPaymentAmount!: number;

  paymentPlanForm!: FormGroup;
  balanceResponse!: balanceResponse;
  pendingResponse!: pendingTransactionsResponse;

  billingEmail: string | undefined = undefined;
  todaysDate = new Date();
  private paymentPlanMinStartDate = new Date();
  paymentPlanMinStartDateString = this.paymentPlanMinStartDate
    .toISOString()
    .split('T')[0];
  private paymentPlanMaxStartDate = new Date();
  //calculated in ngOnInit
  paymentPlanMaxStartDateString!: string;

  paymentPlanMinEndDateString = this.paymentPlanMinStartDate
    .toISOString()
    .split('T')[0];
  paymentPlanMaxEndDateString!: '';

  constructor(private fb: FormBuilder) {}

  ngOnInit() {
    this.paymentPlanMaxStartDate.setDate(
      this.paymentPlanMaxStartDate.getDate() + 15
    );

    this.paymentPlanMaxStartDateString = this.paymentPlanMaxStartDate
      .toISOString()
      .split('T')[0];

    if (!this.paymentPlanService.hasGeneratedPlanOptions) {
      const balanceRequest = this.balanceService.response$;
      const pendingRequest = this.pendingTransactionsService.response$;
      const paperlessBillingRequest = this.paperlessBillingService.response$;

      this.initSubscription = combineLatest([
        balanceRequest,
        pendingRequest,
        paperlessBillingRequest,
      ])
        .pipe(
          filter(
            ([val1, val2, val3]) => val1 != null && val2 != null && val3 != null
          )
        )
        .subscribe({
          next: res => {
            if (res) {
              this.clientMinimumPaymentAmount =
                this.clientSettingsService.getClientSettings().minimumPaymentAmount;
              this.clientMaximumNumberOfPayments =
                this.clientSettingsService.getClientSettings().maximumPaymentPlanDuration;

              if (
                this.paperlessBillingService.getPaperlessBillingInfo()
                  .currentEmail
              ) {
                this.billingEmail =
                  this.paperlessBillingService.getPaperlessBillingInfo().currentEmail;
              } else {
                this.billingEmail = '';
              }

              this.setupPaymentPlans();
              this.setupForm();

              if (this.paymentPlanService?.defaultPlans[0]?.numberOfPayments) {
                this.paymentPlanForm
                  .get('additionalFields')
                  ?.get('numberOfPayments')
                  ?.setValue(
                    this.paymentPlanService.defaultPlans[0].numberOfPayments
                  );
              }
            }
          },
        });
      this.paymentPlanService.fetchData();
    } else {
      this.clientMinimumPaymentAmount =
        this.clientSettingsService.getClientSettings().minimumPaymentAmount;
      this.clientMaximumNumberOfPayments =
        this.clientSettingsService.getClientSettings().maximumPaymentPlanDuration;

      this.setupForm();
    }
  }

  setupPaymentPlans() {
    // Build Payment Plan Options
    this.paymentPlanService.setPaymentPlanOptions(
      3,
      this.clientMaximumNumberOfPayments,
      this.clientMinimumPaymentAmount
    );
  }

  setupForm() {
    // Calc information for user and client
    this.guarantorBalance = this.balanceService.getBalance().balance;
    this.minimumPaymentAmount = Number(this.calcMinPaymentAmount());

    // Setup form
    this.paymentPlanForm = new FormGroup({
      paymentPlan: new FormControl(),
      startDate: new FormControl(),
      emailAddress: new FormControl(),
      additionalFields: new FormGroup({
        endDate: new FormControl(),
        paymentAmount: new FormControl(),
        numberOfPayments: new FormControl(),
      }),
    });

    // Define form defaults and validators
    this.paymentPlanForm = this.fb.group({
      paymentPlan: [
        this.paymentPlanService.paymentSelectionsCopy &&
        this.paymentPlanService.paymentSelectionsCopy.paymentPlan !== undefined
          ? this.paymentPlanService.paymentSelectionsCopy.paymentPlan.toString()
          : '0',
        {
          validators: [Validators.required],
        },
      ],
      startDate: [
        formatToDateString(this.todaysDate),
        {
          validators: [
            Validators.required,
            minDateValidator(this.paymentPlanMinStartDateString),
            maxDateValidator(this.paymentPlanMaxStartDateString),
          ],
        },
      ],
      emailAddress: [
        this.billingEmail,
        { validators: [Validators.required, Validators.email] },
      ],
      additionalFields: this.fb.group({
        endDate: [''],
        paymentAmount: [''],
        numberOfPayments: [''],
        guarantorBalance: [this.guarantorBalance],
      }),
    });

    // Setup handler for form data changes
    this.handleFormChange();
    // Patch values from previous submission if present
    if (this.paymentPlanService.paymentSelectionsCopy) {
      this.paymentPlanForm.patchValue(
        this.paymentPlanService.paymentSelectionsCopy
      );
    }

    // Update value and validty
    this.paymentPlanForm.updateValueAndValidity();
  }

  private handleFormChange(): void {
    const paymentPlanControl = this.paymentPlanForm.get('paymentPlan');

    if (paymentPlanControl) {
      this.paymentPlanFormSubscription =
        paymentPlanControl.valueChanges.subscribe(selectedPlanValue => {
          if (selectedPlanValue === '-1') {
            this.isPredefinedPlanSelected = false;
            this.setupUpAdditionalFields();

            const paymentAmountControl = this.paymentPlanForm
              .get('additionalFields')
              ?.get('paymentAmount');
            const startDateControl = this.paymentPlanForm.get('startDate');

            if (paymentAmountControl && startDateControl) {
              this.paymentAmountSubscription = merge(
                paymentAmountControl.valueChanges.pipe(
                  startWith(paymentAmountControl.value)
                ),
                startDateControl.valueChanges.pipe(
                  startWith(startDateControl.value)
                )
              ).subscribe({
                next: () => {
                  const paymentAmount = this.paymentPlanForm
                    .get('additionalFields')
                    ?.get('paymentAmount')?.value;
                  if (
                    paymentAmount &&
                    !isNaN(Number(paymentAmount)) &&
                    Number(paymentAmount) > 0
                  ) {
                    this.paymentPlanForm
                      .get('additionalFields')
                      ?.get('endDate')
                      ?.setValue(this.calcEndDate());
                  }
                },
              });
            }
          } else {
            this.isPredefinedPlanSelected = true;
            this.paymentAmountSubscription &&
              this.paymentAmountSubscription.unsubscribe();
            this.setupUpAdditionalFields();
          }
          this.paymentPlanForm
            .get('additionalFields')
            ?.get('numberOfPayments')
            ?.setValue(this.calcPlanDuration());
        });
    }
  }

  private setupUpAdditionalFields() {
    const additionalFields = this.paymentPlanForm.get(
      'additionalFields'
    ) as FormGroup;

    if (additionalFields.get('paymentAmount')?.value === '') {
      additionalFields
        .get('paymentAmount')
        ?.setValue(this.minimumPaymentAmount);
    }
    additionalFields
      .get('paymentAmount')
      ?.setValidators([
        Validators.required,
        Validators.pattern('^(0|[1-9]\\d*)(\\.\\d{0,2})?$'),
        Validators.min(this.minimumPaymentAmount),
        Validators.max(this.guarantorBalance),
      ]);

    if (!this.isPredefinedPlanSelected) {
      additionalFields.get('endDate')?.setValue(this.calcEndDate());
      additionalFields.get('endDate')?.setValidators([Validators.required]);
    } else {
      additionalFields.get('paymentAmount')?.clearValidators();
      additionalFields.get('endDate')?.clearValidators();
    }
    additionalFields.get('paymentAmount')?.updateValueAndValidity();
    additionalFields.get('endDate')?.updateValueAndValidity();
  }

  calcPlanDuration() {
    let planDuration;
    if (this.isPredefinedPlanSelected) {
      planDuration =
        this.paymentPlanService.defaultPlans[
          this.paymentPlanForm.get('paymentPlan')?.value
        ].numberOfPayments;
    } else {
      planDuration = Math.ceil(
        this.guarantorBalance /
          this.paymentPlanForm.get('additionalFields')?.get('paymentAmount')
            ?.value
      );
    }
    if (planDuration > this.clientMaximumNumberOfPayments) {
      planDuration = this.clientMaximumNumberOfPayments;
    }

    this.paymentPlanForm
      .get('additionalFields')
      ?.get('numberOfPayments')
      ?.setValue(planDuration);

    return planDuration;
  }

  calcEndDate() {
    const planDuration = this.calcPlanDuration();
    const startDate = new Date(this.paymentPlanForm.get('startDate')?.value);
    const calculatedEndDateString = startDate.setMonth(
      startDate.getMonth() + (planDuration - 1)
    );

    const calculatedEndDate = new Date(calculatedEndDateString);

    if (
      this.paymentPlanService.hasProgressedToPaymentInformation &&
      this.paymentPlanService.paymentSelectionsCopy &&
      this.paymentPlanService.paymentSelectionsCopy.additionalFields.endDate
    ) {
      const savedDate =
        this.paymentPlanService.paymentSelectionsCopy.additionalFields.endDate;
      this.paymentPlanService.hasProgressedToPaymentInformation = false;
      return savedDate;
    } else {
      const calculatedEndDateString = formatToDateString(calculatedEndDate);
      return calculatedEndDateString;
    }
  }

  calcMinPaymentAmount() {
    const calculatedMinPaymentAmount =
      this.guarantorBalance / this.clientMaximumNumberOfPayments;
    if (calculatedMinPaymentAmount <= this.clientMinimumPaymentAmount) {
      return this.clientMinimumPaymentAmount;
    } else {
      return toTwoDecimalPlaceNumberRoundUp(
        calculatedMinPaymentAmount.toString()
      );
    }
  }

  onPaymentSubmit() {
    this.paymentPlanService.saveFormValues(
      this.paymentPlanForm.value,
      this.isPredefinedPlanSelected
    );
    this.paymentPlanService.hasProgressedToPaymentInformation = true;
    this.paymentPlanService.nextPage();
  }

  ngOnDestroy() {
    this.initSubscription && this.initSubscription.unsubscribe();
    this.customPlanSubscription && this.customPlanSubscription.unsubscribe();
    this.paymentSelectionsSubscription &&
      this.paymentSelectionsSubscription.unsubscribe();
    this.paymentPlanFormSubscription &&
      this.paymentPlanFormSubscription.unsubscribe();
    this.paymentAmountSubscription &&
      this.paymentAmountSubscription.unsubscribe();
    this.startDateSubscription && this.startDateSubscription.unsubscribe();
  }
}
