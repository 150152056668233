@defer (
  when this.clientSettingsService.complete === true && this.balanceService.complete === true && this.pendingTransactionsService.complete === true
) {
  @if (this.hasOnlinePaymentMethods() === true) {
    <header class="payment__header">
      <h1 class="t-heading payment__header__heading">Pay Bill</h1>
      @if (this.paymentService.paymentLocation !== 3) {
        <h2 class="t-heading-small payment__header__sub-heading">
          Total due today:
          <span class="payment__header__sub-heading__total">{{
            this.balanceDue | currency
          }}</span>
        </h2>
      }
      @if (this.paymentService.paymentLocation === 2) {
        <div
          class="t-heading-medium payment__header__sub-heading payment__header__sub-heading--button">
          <span class="payment__header__sub-heading__content">
            Amount being paid today
            <span
              class="payment__header__sub-heading__content__selected-amount">
              {{ this.paymentService.paymentAmount | currency }}
            </span>
          </span>
          <button
            class="payment__header__sub-heading__button button-secondary button-secondary--outline"
            data-testid="pay-form-edit-amount"
            type="submit"
            (click)="editPayment()">
            Edit Payment Amount
          </button>
        </div>
      }
    </header>

    @if (this.paymentService.paymentLocation === 1) {
      <app-payment-selection />
    } @else if (this.paymentService.paymentLocation === 2) {
      <app-payment-information />
    } @else if (this.paymentService.paymentLocation === 3) {
      <app-payment-confirmation />
    }
  } @else {
    <div class="payment__body">
      <h1 class="visually-hidden">No online payment options</h1>
      <p class="t-body-large">
        To pay your bill by credit card, please call
        <a
          class="t-link"
          [href]="
            'tel:' + this.clientSettingsService.getClientSettings().payOnlyPhone
          ">
          {{ this.clientSettingsService.getClientSettings().payOnlyPhone }}
        </a>
      </p>
    </div>
  }
} @placeholder {
  Loading...
}
