import { HttpContextToken, HttpInterceptorFn } from '@angular/common/http';
import { inject } from '@angular/core';

import { isNullEmptyOrWhitespace } from '../../helpers/string';
import { AuthService } from '../../services/auth/auth.service';

export const ANONYMOUS: HttpContextToken<boolean> = new HttpContextToken(
  () => false
);

export const authInterceptor: HttpInterceptorFn = (request, next) => {
  const authService = inject(AuthService);

  const anonymous: boolean = request.context.get(ANONYMOUS);
  if (anonymous) return next(request);

  const token = authService.token;

  if (isNullEmptyOrWhitespace(token)) return next(request);

  const cloneRequest = request.clone({
    setHeaders: {
      Authorization: token!,
    },
  });

  return next(cloneRequest);
};
