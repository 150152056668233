<div class="login">
  <div class="login__header">
    <h1 class="login__header__heading t-heading">
      <span>MyDocBill — Anywhere, </span>
      <span>Anytime & Any Way</span>
    </h1>
    <p class="login__header__body t-body">
      Take control of your Patient Experience
    </p>
  </div>

  @defer (when this.clientSettingsService.complete === true) {
    <form [formGroup]="loginForm" class="login__form" (ngSubmit)="onSubmit()">
      <div class="login__form__header">
        <h2 class="login__form__header__heading t-heading-small">
          <app-svg [icon]="'#user-icon'" [inlineSize]="36" [blockSize]="36" />
          <span>Access Your Account</span>
        </h2>
        <p class="login__form__header__body t-body">
          With MyDocBill, you can pay your medical bills, update insurance and
          recall billing history all in one place
        </p>
        <p *ngIf="this.idleMessage" class="login__form__idle-error t-body">
          {{ this.idleMessage }}
        </p>

        <p *ngIf="this.hasAuthError" class="login__form__idle-error t-body">
          {{ this.authError }}
        </p>
      </div>
      <fieldset
        class="login__form__fieldset login__form__fieldset--account-number">
        <legend class="login__form__fieldset__legend t-form-heading">
          Account Number
        </legend>
        <div class="login__form__fieldset__grid">
          <label
            class="login__form__fieldset__grid__label login__form__fieldset__grid__label--account-id">
            <span class="visually-hidden">Account ID</span>
            <input
              class="login__form__fieldset__grid__label__input"
              formControlName="accountId"
              data-testid="login-form-accountId"
              type="text"
              inputmode="numeric"
              placeholder="123456"
              pattern="[0-9]+"
              required="required"
              (blur)="axErrorHandler($event, 'accountIdError')"
              aria-required="true" />
          </label>
          <span class="login__form__fieldset__grid__separator">&ndash;</span>
          <label
            class="login__form__fieldset__grid__label login__form__fieldset__grid__label--client-id">
            <span class="visually-hidden">Client ID</span>
            <input
              class="login__form__fieldset__grid__label__input"
              type="text"
              formControlName="clientId"
              data-testid="login-form-clientId"
              placeholder="QT200"
              pattern="[a-zA-Z0-9]+"
              required="required"
              [attr.readonly]="this.readOnlyClientId ? true : null"
              (blur)="axErrorHandler($event, 'clientIdError')"
              aria-required="true" />
          </label>
          <ng-container
            *ngIf="
              this.clientSettingsService.getClientSettings()
                .supportsBillingGroups
            ">
            <span class="login__form__fieldset__grid__separator">&ndash;</span>
            <label
              class="login__form__fieldset__grid__label login__form__fieldset__grid__label--billing-group">
              <span class="visually-hidden">Billing Group</span>
              <input
                class="login__form__fieldset__grid__label__input"
                type="text"
                formControlName="billingGroup"
                data-testid="login-form-billingGroup"
                placeholder="12"
                [attr.readonly]="this.readOnlyBillingGroup ? true : null"
                pattern="[a-zA-Z0-9]*"
                (blur)="axErrorHandler($event, 'billingGroupError')" />
            </label>
          </ng-container>
        </div>
        <p
          class="login__form__fieldset__hint t-form-hint"
          *ngIf="
            this.clientSettingsService.getClientSettings().supportsBillingGroups
          ">
          You might only have one hyphen (-) with no letters or numbers in the
          last box.
        </p>
      </fieldset>

      <fieldset class="login__form__fieldset">
        <legend class="login__form__fieldset__legend t-form-heading">
          Guarantor Date of Birth
        </legend>
        <div class="login__form__fieldset__grid">
          <label
            class="login__form__fieldset__grid__label login__form__fieldset__grid__label--dob-month">
            <span class="login__form__fieldset__grid__label__text t-form-label"
              >Month</span
            >
            <input
              class="login__form__fieldset__grid__label__input"
              type="text"
              inputmode="numeric"
              formControlName="dobMonth"
              data-testid="login-form-dobMonth"
              placeholder="MM"
              required="required"
              pattern="^[0-9]{1,2}$"
              maxlength="2"
              (blur)="axErrorHandler($event, 'dobMonthError')"
              aria-required="true" />
          </label>
          <label
            class="login__form__fieldset__grid__label login__form__fieldset__grid__label--dob-day">
            <span class="login__form__fieldset__grid__label__text t-form-label"
              >Day</span
            >
            <input
              class="login__form__fieldset__grid__label__input"
              type="text"
              inputmode="numeric"
              formControlName="dobDay"
              data-testid="login-form-dobDay"
              placeholder="DD"
              required="required"
              pattern="^[0-9]{1,2}$"
              maxlength="2"
              (blur)="axErrorHandler($event, 'dobDayError')"
              aria-required="true" />
          </label>
          <label
            class="login__form__fieldset__grid__label login__form__fieldset__grid__label--dob-year">
            <span class="login__form__fieldset__grid__label__text t-form-label"
              >Year</span
            >
            <input
              class="login__form__fieldset__grid__label__input"
              type="text"
              inputmode="numeric"
              formControlName="dobYear"
              data-testid="login-form-dobYear"
              placeholder="YYYY"
              required="required"
              pattern="^[0-9]{4}$"
              maxlength="4"
              (blur)="axErrorHandler($event, 'dobYearError')"
              aria-required="true" />
          </label>
        </div>
      </fieldset>

      <label
        *ngIf="this.allowReCaptcha"
        for="g-recaptcha-response"
        class="login__form__recaptcha">
        <span class="visually-hidden">text</span>
        <re-captcha
          id="recaptcha"
          formControlName="recaptchaReactive"
          (blur)="axErrorHandler($event, 'recaptchaError')">
        </re-captcha>
      </label>

      <ul
        class="login__form__errors"
        data-testid="login-form-errors"
        *ngIf="!loginForm.valid">
        <li
          class="t-body"
          data-testid="login-form-accountIdError"
          *ngIf="
            loginForm.get('accountId')?.touched &&
            loginForm.get('accountId')?.errors
          ">
          {{ this.formErrors.accountIdError }}
        </li>
        <li
          class="t-body"
          data-testid="login-form-clientIdError"
          *ngIf="
            loginForm.get('clientId')?.touched &&
            loginForm.get('clientId')?.errors
          ">
          {{ this.formErrors.clientIdError }}
        </li>
        <li
          class="t-body"
          data-testid="login-form-billingGroupError"
          *ngIf="
            loginForm.get('billingGroup')?.touched &&
            loginForm.get('billingGroup')?.errors
          ">
          {{ this.formErrors.billingGroupError }}
        </li>
        <li
          class="t-body"
          data-testid="login-form-dobMonthError"
          *ngIf="
            loginForm.get('dobMonth')?.touched &&
            loginForm.get('dobMonth')?.errors
          ">
          {{ this.formErrors.dobMonthError }}
        </li>
        <li
          class="t-body"
          data-testid="login-form-dobDayError"
          *ngIf="
            loginForm.get('dobDay')?.touched && loginForm.get('dobDay')?.errors
          ">
          {{ this.formErrors.dobDayError }}
        </li>
        <li
          class="t-body"
          data-testid="login-form-dobYearError"
          *ngIf="
            loginForm.get('dobYear')?.touched &&
            loginForm.get('dobYear')?.errors
          ">
          {{ this.formErrors.dobYearError }}
        </li>
        <li
          class="t-body"
          data-testid="login-form-recaptchaError"
          *ngIf="
            loginForm.get('recaptchaReactive')?.touched &&
            loginForm.get('recaptchaReactive')?.errors
          ">
          {{ this.formErrors.recaptchaError }}
        </li>
      </ul>

      <div class="login__form__submit">
        <!-- TODO: Add Terms of Use link -->
        <p class="login__form__submit__body t-form-hint">
          By clicking 'Sign In' below, I convey that I agree to the
          <a
            [href]="this.termsOfUseUrl"
            class="t-link"
            target="_blank"
            data-testid="login-terms-of-use-link">
            Terms of Use
            <span class="visually-hidden">(opens in a new window)</span>
          </a>
        </p>
        <button
          class="login__form__submit__button button"
          data-testid="login-form-submit"
          type="submit"
          [disabled]="!loginForm.valid">
          Sign In
        </button>
        @if (
          this.isGlobalSite &&
          this.clientSettingsService.getClientSettings().enableAccountLookup
        ) {
          <a
            class="login__form__submit__button button-secondary button-secondary--outline"
            routerLink="./account-lookup">
            Need help logging in?
          </a>
        }
      </div>
    </form>
  } @error {
    <p class="t-body">Error loading page</p>
  }
</div>
@defer (when this.clientSettingsService.complete === true) {
  <router-outlet></router-outlet>
}
