import { CommonModule } from '@angular/common';
import { Component, inject, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';

import { ClientSettingsService } from '../../services/client-settings/client-settings.service';
import { PaymentAccessTokenService } from '../../services/payment-access-token/payment-access-token.service';
import { PaymentDestinationService } from '../../services/payment-destination/payment-destination.service';
import { PaymentLibraryService } from '../../services/payment-library/payment-library.service';

import { PaymentPlanConfirmationComponent } from './payment-plan-confirmation/payment-plan-confirmation/payment-plan-confirmation.component';
import { PaymentPlanInformationComponent } from './payment-plan-information/payment-plan-information/payment-plan-information.component';
import { PaymentPlanSelectionComponent } from './payment-plan-selection/payment-plan-selection.component';
import { PaymentPlanServiceService } from './services/payment-plan-service.service';

@Component({
  selector: 'app-payment-plan',
  standalone: true,
  imports: [
    PaymentPlanSelectionComponent,
    CommonModule,
    PaymentPlanInformationComponent,
    PaymentPlanConfirmationComponent,
  ],
  templateUrl: './payment-plan.component.html',
  styleUrl: './payment-plan.component.scss',
})
export class PaymentPlanComponent implements OnInit, OnDestroy {
  paymentPlanService = inject(PaymentPlanServiceService);
  paymentAccessTokenService = inject(PaymentAccessTokenService);
  paymentLibraryService = inject(PaymentLibraryService);
  paymentDestinationService = inject(PaymentDestinationService);
  clientSettingsService = inject(ClientSettingsService);

  paymentLibraryCode: string | undefined = undefined;
  paymentLibrarySubscription: Subscription | undefined;

  ngOnInit() {
    this.paymentLibraryService.fetchPaymentLibrary();
    this.paymentLibrarySubscription =
      this.paymentLibraryService.response$.subscribe({
        next: (res: string) => {
          if (res !== '') {
            this.paymentLibraryCode = res;
            if (
              this.paymentLibraryCode !== '' ||
              this.paymentLibraryCode !== undefined
            ) {
              if (typeof this.paymentLibraryCode === 'string') {
                this.paymentAccessTokenService.fetchPaymentAccessToken();
                this.paymentLibraryService.generatePaymentLibraryScriptTag(
                  this.paymentLibraryCode
                );
                this.paymentDestinationService.fetchPaymentDestination();
              }
            }
          }
        },
      });
  }

  editPayment() {
    this.paymentPlanService.copySelections();
    this.paymentPlanService.previousPage();
  }

  submitPayment() {
    this.paymentPlanService.nextPage();
  }

  hasOnlinePaymentMethods() {
    const acceptsCC =
      this.clientSettingsService.getClientSettings().acceptCreditCards;
    const acceptsEC =
      this.clientSettingsService.getClientSettings().acceptElectronicChecks;

    return acceptsCC || acceptsEC;
  }

  ngOnDestroy() {
    if (this.paymentLibrarySubscription) {
      this.paymentLibrarySubscription.unsubscribe();
    }
    this.paymentPlanService.resetPaymentPlanFlow();
  }
}
