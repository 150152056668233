import { HttpClient, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

import { environment } from '../../../environments/environment';

export interface billingGroupSelectionResponse {
  billingGroupId: number;
  billingGroupName: string;
}

@Injectable({
  providedIn: 'root',
})
export class BillingGroupSelectionService {
  endPointBase: string = '/BillingGroupSelection';

  response!: billingGroupSelectionResponse;

  complete: boolean = false;

  private responseSubject = new BehaviorSubject<
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    billingGroupSelectionResponse | any
  >(null);
  response$ = this.responseSubject.asObservable();

  constructor(private http: HttpClient) {}

  fetchBillingGroups() {
    this.http
      .post<billingGroupSelectionResponse>(
        environment.apiUrl + this.endPointBase,
        {},
        {
          observe: 'response',
        }
      )
      .subscribe({
        next: (res: HttpResponse<billingGroupSelectionResponse>) => {
          if (res !== null && res.status === 200 && res.body) {
            this.response = res.body;
            this.responseSubject.next(res.body);
            if (res !== null && res.body) {
              this.complete = true;
            }
          }
        },
      });
  }
}
