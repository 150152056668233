import { HttpClient, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

import { environment } from '../../../environments/environment';
import { VirtualSiteService } from '../virtual-site/virtual-site.service';

export interface faqResponse {
  question: string;
  answer: string;
}

@Injectable({
  providedIn: 'root',
})
export class FaqService {
  endPointBase: string = '/Faq';

  response: faqResponse[] = [
    {
      question: '',
      answer: '',
    },
  ];
  complete: boolean = false;

  private responseSubject = new BehaviorSubject<faqResponse[]>(this.response);
  response$ = this.responseSubject.asObservable();

  constructor(
    private http: HttpClient,
    private virtualSiteService: VirtualSiteService
  ) {}

  fetchFaq() {
    this.http
      .post<faqResponse[]>(
        environment.apiUrl + this.endPointBase,
        { siteUrl: this.virtualSiteService.site.url },
        {
          observe: 'response',
        }
      )
      .subscribe({
        next: (res: HttpResponse<faqResponse[]>) => {
          if (res.body) {
            this.response = res.body;
            this.responseSubject.next(res.body);
            this.complete = true;
          }
        },
      });
  }

  getFaq() {
    return this.response; // Return data stored in the service
  }
}
