import { Component, inject, OnInit } from '@angular/core';
import { RouterOutlet } from '@angular/router';

import { BillingGroupModalComponent } from '../billing-group-modal/billing-group-modal.component';
import { AuthService } from '../services/auth/auth.service';

@Component({
  selector: 'app-dashboard',
  standalone: true,
  templateUrl: './dashboard.component.html',
  styleUrl: './dashboard.component.scss',
  imports: [RouterOutlet, BillingGroupModalComponent],
})
export class DashboardComponent implements OnInit {
  authService = inject(AuthService);
  needsBillingGroup: boolean = false;

  ngOnInit(): void {
    this.needsBillingGroup = this.authService.isPartiallyAuthenticated();
  }
}
