import { ApplicationConfig, ApplicationRef, Type } from '@angular/core';

import { AppComponent } from './app.component';
import { AppConfig } from './app.config';
import { Logger } from './observability/logger';

export class AppLoader {
  constructor(
    private bootstrapApplication: (
      rootComponent: Type<unknown>,
      options?: ApplicationConfig
    ) => Promise<ApplicationRef>
  ) {}

  async load(): Promise<boolean> {
    function showLoading() {
      const loading = document.getElementById('splash-loading');
      loading!.classList.replace('hidden', 'shown');
    }

    function showApplication() {
      // Uncomment setTimeout to simulate a delay fetching dynamic application configuration.

      // setTimeout(async () => {
      isLoading = false;
      const splash = document.getElementById('splash');
      splash!.classList.replace('shown', 'hidden');
      // }, 15000);
    }

    function showError() {
      isLoading = false;
      const loading = document.getElementById('splash-loading');
      loading!.classList.replace('shown', 'hidden');
      const error = document.getElementById('splash-error');
      error!.classList.replace('hidden', 'shown');
    }

    // We expect the site to load quickly. To avoid flicker, only show the loading
    // indicator if the user is waiting an exceptionally long time.
    setTimeout(function () {
      if (isLoading) {
        showLoading();
      }
    }, 2000);

    const logger = new Logger('Main');
    logger.debug('Started');

    let isLoading = true;

    try {
      const configuration = await AppConfig.buildConfiguration();

      const application = await this.bootstrapApplication(
        AppComponent,
        configuration
      );

      // application.viewCount is read to make await Main.bootstrapApplication(...) blocking
      logger.debug('Ready', { viewCount: application.viewCount });

      showApplication();
    } catch (error: unknown) {
      logger.error('Failed', {}, error);
      showError();
      return false;
    }

    return true;
  }
}
